import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';


function Toast(props) {
    return (
        props.show
            ? <div className="fadeIn animated toast-box">
                <div className="slideInUp animated toast-modle" onClick={e => e.stopPropagation()}>
                    {/* <div className="title">{props.title}</div> */}
                    <div className="content">{props.content}</div>
                </div>
            </div>
            : null
    );
}

export function showToast(content, timeout = 1500) {
    const wrap = document.querySelector('#toast-wrap')
    if (wrap) {
        wrap.remove()
    }
    const w = document.createElement('div')
    w.setAttribute('id', 'toast-wrap')
    const cc = (
        <div className="fadeIn animated toast-box">
            <div className="slideInUp animated toast-modle" onClick={e => e.stopPropagation()}>
                {/* <div className="title">{props.title}</div> */}
                <div className="content">{content}</div>
            </div>
        </div>
    ) 
    document.body.append(w)
    ReactDOM.render(cc, w)
    setTimeout(() => {
        w.remove()
    }, timeout);
}

export default Toast