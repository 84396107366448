import React from 'react';

function Page() {
    document.title = 'F&Q';
    return (
       <div>
           FAQ
       </div>
    )
}

export default Page