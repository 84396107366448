import Cookies from "js-cookie"

/*
 * @Author: zhao - 🍉
 * @Date: 2022-02-07 21:52:29
 * @LastEditTime: 2022-03-07 16:20:51
 * @LastEditors: zhao - 🍉
 * @Description: 用户登录信息处理
 * @FilePath: /zhzj-h5/src/util/auth.js
 */


/**
 * 设置登录信息
 *
 * @param {*} key
 * @returns
 */
export const authSetUserInfo = (data) => {
  const json = JSON.stringify(data)
  Cookies.set('loginData', json)
}

/**
 * 获取登录信息
 *
 * @param {*} key
 * @returns
 */
export const authGetUserInfo = (key) => {
  const json =  Cookies.get('loginData')
   let data = {}
   try {
    data = JSON.parse(json)
   } catch (error) {}

   if (key) return data[key]
   return data
   
}


/**
 * 清理登录信息
 *
 * @returns
 */
 export const authClearUserInfo = () => {
  Cookies.remove('loginData')
}