import React, { useContext } from 'react';
import { context } from '../../store';
import './index.scss';

function Page(props) {
    document.title = '选择所属银行';
    const { dispatch } = useContext(context);

    const bankList = [
        {
            icon: <i className="iconfont" style={{ color: 'rgb(175, 36, 52)' }}>&#xe61f;</i>,
            text: '中国银行',
            key: 1
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(236, 104, 27)', fontSize: '.3rem' }}>&#xe983;</i>,
            text: '平安银行',
            key: 2
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(0, 145, 116)' }}>&#xe62b;</i>,
            text: '农业银行',
            key: 3
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(199, 22, 46)' }}>&#xe621;</i>,
            text: '招商银行',
            key: 4
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(198, 0, 11)' }}>&#xe660;</i>,
            text: '工商银行',
            key: 5
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(0, 125, 62)' }}>&#xe657;</i>,
            text: '邮政储蓄银行',
            key: 6
        },
        {
            icon: <i className="iconfont" style={{ color: 'rgb(14, 52, 132)' }}>&#xe63b;</i>,
            text: '建设银行',
            key: 7
        },
    ]

    function handleSelect(text) {
        dispatch({ type: 'addBank', payload: { bankType: text } });
        props.history.goBack();
    }

    return (
        <div className="add-bank-select">
            <ul className="bank-list">
                {
                    bankList.map((item, index) => {
                        return <li className="list-item" key={item.key} onClick={() => { handleSelect(item.text) }}>
                            <div className="icon">{item.icon}</div>
                            <span className="txt">{item.text}</span>
                        </li>
                    })
                }
            </ul>
        </div>
    );
}

export default Page