import React, { useState, useEffect, Fragment } from 'react';
import Tips from '../../component/Tips';
import Hint from '../../component/Hint';
import { post, postFrom, config } from '../../axios';
import './index.scss';

function Page(props) {
    document.title = '我的银行卡';
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [time, setTime] = useState(1);
    const [bankData, setBankData] = useState([]);
    const [activeBank, setActiveBank] = useState('');

    useEffect(() => {
        post({url: config.API_bank_findBankList}).then(res => {
            console.log("银行卡数据：",res.data);
            setBankData(res.data)
        }).catch(err => {
            console.log(err)
        })
    },[]);

    function selectBank (value){
        switch (value) {
            case "建设银行":
                return (
                    <i className="iconfont" style={{ color : 'blue'}}>&#xe63b;</i>
                )
            case "农业银行":
                return (
                    <i className="iconfont" style={{ color : 'green'}}>&#xe62b;</i>
                )
            case "工商银行":
                return (
                    <i className="iconfont">&#xe660;</i>
                )
            case "平安银行":
                return (
                    <i className="iconfont" style={{ color : 'orange'}} >&#xe983;</i>
                )
            case "招商银行":
                return (
                    <i className="iconfont">&#xe621;</i>
                )
            case "邮政储蓄银行":
                return (
                    <i className="iconfont" style={{ color: 'rgb(0, 125, 62)' }}>&#xe657;</i>
                )
        }
    }

    function unBindBank() {
        const data = {'customerbankcardId' : activeBank};
        postFrom({
            url: config.API_bank_unBinding,
            data
        }).then(res => {
            if(res.code === 0){
                setShow1(true)
                aaa();
            }
        }).catch(err => {
            console.log(err)
        })
        setShow(false);
    }

    function aaa (){
        setTimeout(() =>{
            setTime(0)
            setShow1(false);
            window.location.reload();
        },1000)
    }
    return (
        <div className="bank">
            <ul className="bank-list">
                {
                    bankData.length > 0 ?
                        bankData.map((item) => {
                            return (
                                <li className="bank-item" key={item.id}>
                                    <div className="name-message">
                                        {selectBank(item.bank)}
                                        <span className="name">{item.bank}</span>
                                    </div>
                                    <div className="bank-no">{item.cardNumber.replace(/(.{4})/g,'$1 ')}</div>
                                    {
                                        item.status == 1 ?
                                        <button className="untying" onClick={() => { setShow(true);setActiveBank(item.id) }}>解绑</button>
                                        : null
                                    }
                                    
                                </li>
                            )
                        })
                    : null
                }
            </ul>
            <div className="add-bank-box">
                <button className="add-bank" onClick={() => { props.history.push('/addBank') }}>+添加银行卡</button>
            </div>
            <Tips
                colse={() => { setShow(false) }}
                show={show}
                title="提示"
                content={<div style={{ margin: '.3rem 0 .4rem', textAlign: 'center' }}>您确定取消绑定当前银行卡？</div>}
                cancelText="暂不解绑"
                confirmText="确定解绑"
                confirmFn={ unBindBank }
            />
            <Hint
                show={show1}
                time={time}
            />
        </div>
    );
    
}

export default Page