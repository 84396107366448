import React from "react";
import tips1 from "../../image/pay-tips-1.jpg";
import tips2 from "../../image/pay-tips-2.jpg";
function PayTips() {
  const styles = {
    width: '100%',
    display: 'block'
  }
  return (
    <div>
      <img style={styles} src={tips1} alt="" />
      <img style={styles} src={tips2} alt="" />
    </div>
  )
}

export default PayTips