/*
 * @Author: zhao - 🍉
 * @Date: 2022-02-08 22:12:01
 * @LastEditTime: 2022-02-08 22:22:28
 * @LastEditors: zhao - 🍉
 * @Description: 租客人脸认证中间页
 * @FilePath: /zhzj-h5/src/page/FaceAuth/index.jsx
 */
import React from 'react'
import  "./index.scss";
import faceSrc from "../../image/face-check.png";
import { post } from '../../axios';
import { API_getFaceIdentityUrl } from '../../axios/configs';
const FaceCheckView = (props) => {
  


  const postFaceLinkGet = () => {
    post({ url: API_getFaceIdentityUrl }).then(res => {
      window.location.href = res.data
    })
  }

  const handleClose = () => {
    window.WeixinJSBridge.call('closeWindow');
  }
  return (
    <div className='faceCheckView'>
      <div className="h1">人脸认证</div>
      <img src={faceSrc} alt="" />
      <div className='h2'>请将您的脸部放在识别的指示框内</div>
      <div className='h2'>以便于准确识别个人信息</div>
      <button className={`form-btn tran3`} onClick={postFaceLinkGet}>人脸认证</button>
      <button className={`form-btn tran3 sub`} onClick={handleClose} >暂不认证</button>
      
    </div>
  )
}

export default FaceCheckView