/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-30 17:35:19
 * @LastEditTime: 2022-02-09 20:42:28
 * @LastEditors: zhao - 🍉
 * @Description: 签署成功页面
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/SignResult.jsx
 */
import React, { useEffect } from 'react';
import { APP_DOWNLOAD_URL } from "../../../common/config";

import '../index.scss';


function SignResult(props) {
    useEffect(() => {
        window.location.href = APP_DOWNLOAD_URL
    },[])
    return (
        <div className="face-recognition">
            <div className="processing">
                <i className="iconfont icon">&#xe6b5;</i>
                <div className="exp">签署成功，请稍等</div>
            </div>
        </div>
    );
}

export default SignResult