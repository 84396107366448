

import { API_NAME } from "../common/config";

export let HOST = window.location.origin + API_NAME;
/**
 * @description: 登录
 */
export const API_open_home_sendVerifyCode = HOST + '/open/home/sendVerifyCode'; //短信验证码校验
export const API_open_home_login = HOST + '/weixin/customer/login'; //短信登录
export const API_open_home_loginByWeixinCode = HOST + '/weixin/customer/loginByWeixinCode'; //网页授权自动登录
export const API_customer_getWeixinOpenid = HOST + '/weixin/customer/getWeixinOpenid'; //通过网页授权跳转的code换取openid(成功时data为openid)

/**
 * @description: 账单
 */
export const API_bill_findBill = HOST + '/weixin/customer/findBill'; //获取账单

/**
 * @description: 个人中心
 */

export const API_personal_findCustomer = HOST + '/weixin/customer/findCustomer'; //获取个人中心
export const API_customer_logout = HOST + '/weixin/customer/logout'; //退出登录

/**
 * @description: 银行卡
 */

export const API_bank_findBankList = HOST + '/weixin/customer/customerbankcard/findList'; //获取全部银行卡
export const API_bank_findOneBank = HOST + '/weixin/customer/customerbankcard/getById'; //获取单个银行卡
export const API_bank_createBank = HOST + '/weixin/customer/customerbankcard/create'; //新增、修改银行卡信息
export const API_bank_unBinding = HOST + '/weixin/customer/customerbankcard/unbundlingBankcard'; //解绑

export const API_upload_uploadfile = HOST + '/weixin/upload/upload_file'; //上传身份证信息
export const API_bestsign_getSignUrl = HOST + '/weixin/bestsign/getSignUrl'; //请求电子合同的接口地址
export const API_getFaceIdentityUrl = HOST + '/weixin/customer/getFaceIdentityUrl' // 请求租客人脸地址
export const API_bestsign_getPreviewUrl = HOST + '/weixin/bestsign/getPreviewUrl'; //电子合同预览地址
export const API_customer_credentialIdentify = HOST + '/weixin/customer/credentialIdentify'; //实名验证通过

export const API_customer_getJsapiPayInfo = HOST + '/weixin/customer/getJsapiPayOrderInfo_yl'; //微信支付
export const getJsapiPayInfo_SZRMB = HOST + '/weixin/customer/getJsapiPayOrderInfo_SZRMB'; //数字人民币支付链接
export const getPayUrl_HSB = HOST + '/weixin/customer/getPayUrl_HSB'; //惠市宝支付链接
export const API_customer_getDiscount = HOST + '/weixin/customer/getDiscountCoupon'; // 获取优惠券

export const API_customer_getJsapiPayInfo2 = HOST + '/weixin/customer/getPayUrl_Yl'; //支付

export const API_attach_list = HOST + '/weixin/customer/getContractAttach' // 获取附件


export const API_acceptYLPayNotify = HOST + '/open/home/acceptYLPayNotify'

export const API_acceptCcbNotify = HOST + '/open/home/ccbNotify'

export const API_acceptHSBNotify = HOST + '/open/hsb/payreback/gatherPayNotice'

export const API_PaymentCallback = HOST + '/open/Ccb/paymentCallback'

export const API_customer_login = HOST + '/weixin/customer/loginPhone' // 第三方平台登录