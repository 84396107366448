import React from 'react'
import WxImageViewer from 'react-wx-images-viewer';

import Toast from '../../component/Toast';
import ImageView from "../../component/ImageView";
import { post, config, postFrom } from '../../axios';
import iconFile from "../../image/icon-file.png";
import "./index.scss";

const imgTypeMap = ['jpg', 'jpeg', 'png', 'gif', 'bmp']

class AttachModal extends React.Component{
    state = {
        
        attachList:[],

        toastOption: { show: false, exp: '' },
        previewOption: {show: false , list: [], index:0}
    }
    componentDidMount() {
        const { contractId } = this.props.match.params;
        this.postAttachList(contractId)
    }
    open(contractId) {
        this.setState({ visible: true })
        
        
        
    }
    setMessage(option) {
        this.setState({toastOption:option})
    }
    showMessage(message) {
        this.setMessage({ show: true, exp: message || '获取信息失败' });
        setTimeout(() => {
            this.setMessage({ show: false, exp: '' });
        }, 2000);
    }
    
    postAttachList(contractId) {
        this.setMessage({ show: true, exp: '加载中' });
        postFrom({ url: config.API_attach_list, data: { contractId: contractId } }).then(res => {
            
            if (res.data) {
                this.setState(state => {
                    state.attachList = res.data
                    state.previewOption.list = res.data.filter(e => imgTypeMap.includes(e.fileType)).map(e => e.fileUrl)
                    return state
                })
                this.setMessage({ show: false, exp: '' });
            } else {
                this.showMessage(res.data.message || '获取信息失败')
            }
        }).catch(err => {
            this.showMessage(err.message || '获取信息失败')
        })
    };
    createImg(current) {
        
        if(imgTypeMap.includes(current.fileType) ) {
            return <ImageView mode="aspectFill" style={{width:'2rem',height: '2rem'}} src={current.fileUrl} />
        }
        return (
            <div style={{width:'2rem',height: '2rem'}} >
                <img style={{width:'1rem', height:'1rem', margin: '0.3rem 0.5rem 0.03rem 0.5rem '}} src={iconFile} alt="" />
                <div style={{textAlign: 'center', color: '#cdcdcd'}}>{current.fileType}文件</div>
            </div>
        )
    }
    handleAttachClick(current) {
        if(imgTypeMap.includes(current.fileType) ) {
            this.setState(state => {
                state.previewOption.show = true
                state.previewOption.index = state.previewOption.list.findIndex(e => e === current.fileUrl)

                return state
            })
        }else {
            window.location.href = current.fileUrl
        }
    }
    handlePreviewClose() {
        this.setState(state => {
            state.previewOption.show = false
            return state
        })
    }
    render(){
        const modalConfig = {
            visible: this.state.visible,
            title: '查看附件',
            onCancel:() => {
                this.setState({
                    visible: false
                })
            }
        }
        return (
            <React.Fragment>
                
                <div className="attach-list">
                    {this.state.attachList.map(e => (
                        <div className="attach-item" key={e.id}>
                            <div className="attach-item-img" onClick={() => {this.handleAttachClick(e)}}>
                                {this.createImg(e)}
                            </div>
                            <div className="attach-item-name">{e.fileName}</div>
                        </div>
                    ))}
                    
                </div>
            
                <Toast
                    show={this.state.toastOption.show}
                    content={<div>{this.state.toastOption.exp}</div>}
                />
                {this.state.previewOption.show && <WxImageViewer urls={this.state.previewOption.list} index={this.state.previewOption.index} onClose={() => {this.handlePreviewClose()}}></WxImageViewer>}
            </React.Fragment>
            
        )
    }
}
export default AttachModal