import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TabbarWrap from '../../component/TabbarWrap';

import Toast from '../../component/Toast';
import ModalText from '../../component/ModalText'
import moment from 'moment';
import './index.scss';
import { post, postFrom, config } from '../../axios';
import AttachModal from '../../container/AttachModal';
import { util_browser } from '../../util';
import BillListView from "../../container/BillListView";

function Page(props) {


    document.title = '账单';

    const [houses, setHouses] = useState([]); // 合同列表
    const [activeHouse, setActiveHouse] = useState({}); // 当前选中的合同
    
    const [billData, setBillData] = useState([]); // 全部账单
    const [currentBillData, setCurrentBillData] = useState([]); // 当前合同的订单
  
    const [message, setMessage] = useState({ show: false, exp: '' });
    const [visible, setVisible] = useState(false)
    

    const modalConfig = {
      visible,
      title: '温馨提示',
      onOk: () => {
        setVisible(false)
      }
    }
    


  


    function postBillList() {
        post({ url: config.API_bill_findBill }).then(res => {
            setMessage({ show: false, exp: '' });
            setBillData(res.data.bills)
            if (res.data.houses.length) {
                setHouses(res.data.houses);
                const active = res.data.houses[0]
                setActiveHouse(active);
            }
        }).catch(err => {
            showMessage(err.message || '获取信息失败');
        })
    }
    // 检测 合同ID变化 更新订单
    useEffect(() => {
        let bills = billData.filter(item => item.contractId === activeHouse.contractId)
        setCurrentBillData(bills);
    }, [activeHouse.contractId]);

    useEffect(() => {
        setMessage({ show: true, exp: '加载中' });

        if (props.location.state && props.location.state.alertNum == 2) {
          setVisible(true)
        }
        postBillList()
        
    }, [props.location.state]);

    function showMessage(message) {
        setMessage({ show: true, exp: message || '获取信息失败' });
        setTimeout(() => {
            setMessage({ show: false, exp: '' });
        }, 2000);
    };

   

    // 切换 合同 设置账单数据
    function handleSwitch() {
        
        let index = houses.findIndex(item => item.contractId === activeHouse.contractId);
        if (index > -1 && index < houses.length - 1) {
            // 有存在值，且不是最后一个
            setActiveHouse(houses[index + 1]);
        } else {
            setActiveHouse(houses[0]);
            
        }
    };

    

    
    
      // 获取当前合同的是否为建融
      function getCurrentUserIsJR(list) {
        let result = false
        list.forEach(e => {
            if (e.isJh ===1) {
                result = true
            }
        })
        return result
    }


    function getCurrentUserIsHsb(list) {
          console.log("惠市宝支付判断");
        let result = false
        list.forEach(e => {
            console.log(e.isHsb ===1);
            if (e.isHsb ===1 || e.isHsb==='1') {
                result = true
            }
        })
        return result
    }

   

    function handlePay(data) {
        localStorage.setItem('pay',JSON.stringify(data))


        if(getCurrentUserIsJR(data) || util_browser().weixin || getCurrentUserIsHsb(data)){
            props.history.push('/pay/ys');
        }else{
            showMessage('支付功能目前仅支持微信浏览器')
        }
    };

    // 获取预览地址
    function handleGetPreviewUrl(current) {
        if(current.createElectronicContract ) {
            postPreviewUrl(current.contractId)
        } else {
            props.history.push('/attachPage/' + current.contractId)
        }
    };
    
    function postPreviewUrl(contractId) {
        setMessage({ show: true, exp: '加载中' });
        postFrom({ url: config.API_bestsign_getPreviewUrl, data: { rentContractId: contractId } }).then(res => {
            if (res.data) {
                setMessage({ show: false, exp: '' });
                window.location.href = res.data.url;
            } else {
                showMessage(res.data.message || '获取信息失败')
            }
        }).catch(err => {
            showMessage(err.message || '获取信息失败')
        })
    }

    // 获取签约地址
    // function handleGetSignUrl(contractId) {
    //     setMessage({ show: true, exp: '加载中' });
    //     post({ url: config.API_bestsign_getSignUrl, data: { rentContractId: contractId } }).then(res => {
    //         if (res.data) {
    //             setMessage({ show: false, exp: '' });
    //             let data = JSON.parse(res.data);
    //             window.location.href = data.shortUrl;
    //         } else {
    //             showMessage(res.data.message || '获取信息失败')
    //         }
    //     }).catch(err => {
    //         showMessage(err.message || '获取信息失败')
    //     })
    // };
   
    return (
        <TabbarWrap>
            
            <AttachModal />
            <div className="bill">
                {
                    houses.length
                        ? <div className="head" >
                            <div className="house-info">
                                <p><i className="iconfont">&#xe603;</i><span>{activeHouse.buildingName} - {activeHouse.roomNo} </span></p>
                                {/* <p><i className="iconfont">&#xe63e;</i><span>{activeHouse.buildingAddress}</span></p> */}
                                <p><i className="iconfont">&#xe60b;</i><span>{moment(activeHouse.beginDate).format('YYYY.MM.DD')} - {moment(activeHouse.endDate).format('YYYY.MM.DD')}</span></p>
                                {
                                    houses.length > 1
                                        ?
                                        <div className="switching">
                                            <div className="switch-wrap">
                                                <i className="iconfont">&#xe606;</i>
                                                <span onClick={handleSwitch}>切换</span>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            
                            <div className="contract">
                                <span className="contract-left">《房屋租赁合同》</span>
                                <span className="contract-right-view" onClick={() => { handleGetPreviewUrl(activeHouse) }}>查看&gt;</span>
                                {
                                    // activeHouse.signStatus === 2
                                    //     ? <span className="contract-right-view" onClick={() => { handleGetPreviewUrl(activeHouse.contractId) }}>查看 ></span>
                                    //     : null //<span className="contract-right-sign" onClick={() => { handleGetSignUrl(activeHouse.contractId) }}>签署</span>
                                }
                            </div>
                                
                           
                        </div>
                        : null
                }
                {currentBillData.length > 0
                    ? <Fragment>
                        <div className="bill-details-wrap">
                            <div className="bill-details">
                                <div><span className="bar"></span>账单</div>
                                <Link className="more" to={'/bill/more/' + activeHouse.houseId + '/' + activeHouse.contractId} >更多账单&gt;<span></span></Link>    
                            </div>

                           

                           <BillListView billData={currentBillData} onPay={handlePay} />
                        </div>
                        
                        
                              
                    </Fragment>
                    : null
                }
                <Toast
                    show={message.show}
                    content={<div>{message.exp}</div>}
                />

                <ModalText {...modalConfig}>
                  <div class="sub-title">
                    亲爱的住户：
                  </div>
                  <div>
                  　　为保证平台数据完整，避免影响交付租金费用，烦请移步首页"签约付租"完成电子签约(与线下签约内容一致)，给您带来的不便敬请谅解！
                  </div>
                </ModalText>

                
            </div>
        </TabbarWrap>
    );
}

export default Page