/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-30 16:18:43
 * @LastEditTime: 2022-02-08 22:30:17
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - 人脸中间页
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/FaceView.jsx
 */
import React, { useEffect, useState } from 'react'
import  "../index.scss";
import faceSrc from "../../../image/face-check.png";
import { fetchHouseKeeperAuthFaceLinkGet } from '../../../services/housekeeperAuth';
import Spin from "../../../component/Spin";
const FaceCheckView = (props) => {
  const [loading, setLoading] = useState(null);


  const postFaceLinkGet = () => {
    setLoading(true)
    fetchHouseKeeperAuthFaceLinkGet().then(res => {
      window.location.href = res.data
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleClose = () => {
    window.WeixinJSBridge.call('closeWindow')
  }
  
  return (
    <Spin spinning={loading}>
      <div className='faceCheckView'>
        <div className="h1">人脸认证</div>
        <img src={faceSrc} alt="" />
        <div className='h2'>请将您的脸部放在识别的指示框内</div>
        <div className='h2'>以便于准确识别个人信息</div>
        <button className={`form-btn tran3`} onClick={postFaceLinkGet}>人脸认证</button>
        <button className={`form-btn tran3 sub`} onClick={handleClose}>暂不认证</button>
        
      </div>

    </Spin>
  )
}

export default FaceCheckView