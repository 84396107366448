import React, { useEffect, useState } from 'react';
import { context } from '../../store';
import './index.scss';

function Page(props) {
    const { type,from } = props.location.state;
    if(from === 'addBank') document.title = '绑定结果';
   
    const [codeCount, setCodeCount] = useState(3);
    useEffect(() => {
        let timer = null;
        if (codeCount <= 3 && codeCount > -1) {
            if (codeCount === 0) {
                if(type === 1){
                    props.history.push('/bill');
                }
            } else {
                timer = setTimeout(() => {
                    setCodeCount(codeCount - 1);
                }, 1000)
            }
        }
        return () => {
            timer && clearTimeout(timer)
        }
    }, [codeCount]);


    return (
        <div className="bind-result">
            {
                type === 1
                    ? <div>
                        <div className="succ"><i className="iconfont">&#xe602;</i></div>
                        <div className="">恭喜您认证成功</div>
                        <div className="count-down">跳转倒计时&#8194;<span>{codeCount}s</span></div>
                    </div>
                    : type === 2
                        ? <div>
                            <div className="fail"><i className="iconfont">&#xe605;</i></div>
                            <div className="">认证失败</div>
                            <div className="count-down count-down2">您的<span>姓名</span>或<span>证件号</span>有误</div>
                            <button className="form-btn tran3" onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/identity')
                            }}>重新认证</button>
                        </div>
                        : null
            }
        </div>
    );
}

export default Page