import React, { useEffect, useState } from 'react';
import './index.scss';
import { postFrom, config } from '../../axios';
import { API_acceptCcbNotify } from '../../axios/configs';

function Page(props) {
    document.title = '数币支付结果';
    // const { type, parState } = props.location.state;
    const [codeCount, setCodeCount] = useState(3);
    const [type, setType] = useState(2)
    const [parState, setParState] = useState(null)

    function getParams(str) {
      const querystr = str.split("?");
      const paramsObj = {};
      if (querystr[1]) {
        const queryList = querystr[1].split("&");
        for (let i = 0; i < queryList.length; i++) {
          let tmp_arr = queryList[i].split("=");
          let key = tmp_arr[0];
          paramsObj[key] = tmp_arr[1];
        }
      }
      return paramsObj;
    }

    useEffect(() => {
      const paramsObj = getParams(props.location.search)
      if (paramsObj.SUCCESS === 'Y') {
        
        postFrom({
          url: API_acceptCcbNotify+"?"+(props.location.search.split("?")[1]),
        }).then(res => {
         setType(1)
        })
      }
    }, [])

    useEffect(() => {
        if (type === 1) {
          let timer = null;
          if (codeCount <= 3 && codeCount > -1 && type === 1) {
              if (codeCount === 0) {
                  if (type === 1) {
                      props.history.push('/bill');
                  }
              } else {
                  timer = setTimeout(() => {
                      setCodeCount(codeCount - 1);
                  }, 1000)
              }
          }
          return () => {
              timer && clearTimeout(timer)
          }
        }
    }, [type, codeCount]);

    return (
        <div className="bind-result">
            {
                type === 1
                    ? <div>
                        <div className="succ"><i className="iconfont">&#xe602;</i></div>
                        <div className="">支付成功</div>
                        <div className="count-down">跳转倒计时&#8194;<span>{codeCount}s</span></div>
                    </div>
                    : type === 2
                        ? <div>
                            <div className="fail"><i className="iconfont">&#xe605;</i></div>
                            <div className="">支付失败</div>
                            <button className="form-btn tran3" onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/bill', parState)
                            }}>重新支付</button>
                        </div>
                        : null
            }
        </div>
    );
}

export default Page