import React from 'react';
import './index.scss';

/**
 * @param {*} props 
 * show:bool 显示弹窗
 * title:string 标题
 * content:reactDom|string 显示内容
 * btnList:array {click: fn, content: reactDom|string} 按钮列表
 */
function Page(props) {
    return (
        props.show
            ? <div
                className="fadeIn animated tips-box"
                onClick={() => {
                    props.colse()
                }}
            >
                <div className="slideInUp animated tips-modle" onClick={e => e.stopPropagation()}>
                    <div className="title">{props.title}</div>
                    <div className="content">{props.content}</div>
                    <div className="btn-list">
                        <button
                            className="btn-item"
                            onClick={() => {
                                props.colse()
                            }}
                        >
                            {props.cancelText || "取消"}
                        </button>
                        <button
                            className="btn-item confirm"
                            onClick={() => {
                                props.confirmFn()
                            }}
                        >
                            {props.confirmText || "确定"}
                        </button>
                    </div>
                </div>
            </div>
            : null
    );
}

export default Page