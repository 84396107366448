/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-17 17:43:11
 * @LastEditTime: 2022-02-07 22:02:05
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - (登录业务主流程控制)
 * @FilePath: /zhzj-h5/src/page/StewardLogin/index.jsx
 */
import React, {useEffect, useState} from 'react'
import StewardLoginForm from './components/LoginFormView'
import IDCardLoginView from "./components/IDCardLoginView";
import FaceCheckView from './components/FaceView';
import SignResult from "./components/SignResult";
import { fetchHouseKeeperAuthStageGet } from '../../services/housekeeperAuth';
import HouseKeeperSignView from './components/SignView';
import {authClearUserInfo, authGetUserInfo} from "../../util/auth";
import Spin from '../../component/Spin';
import {postFrom} from "../../axios";
import {API_acceptYLPayNotify} from "../../axios/configs";

const StewardLogin = (props) => {
    const [stage, setStage] = useState(null);
   const [loading, setLoading] = useState(false);

    useEffect(() => {
        const paramsObj = getParams(props.location.search)
        if (paramsObj.userInfoStatus === 'authClearUserInfo') {
            authClearUserInfo();
        }

      const loginToken = authGetUserInfo('loginToken')
      if (loginToken) {
        postStageCurrentGet()
      } else {
        setStage(0)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleChange = (res) => {
      postStageCurrentGet()
    }

    const getParams =(str) => {
        const querystr = str.split("?");
        const paramsObj = {};
        if (querystr[1]) {
            const queryList = querystr[1].split("&");
            for (let i = 0; i < queryList.length; i++) {
                let tmp_arr = queryList[i].split("=");
                let key = tmp_arr[0];
                paramsObj[key] = tmp_arr[1];
            }
        }
        return paramsObj;
    }

    const postStageCurrentGet = () => {
      setLoading(true)
      fetchHouseKeeperAuthStageGet().then((res) => {
        setStage(res.data)
      }).finally(() => {
        setLoading(false)
      })
    }
    const render = (stage) => {
      // 1=二要素 2=人脸中间页面 3=签署页面 4=签署完成 下载app页面
      if (stage === 0) return <StewardLoginForm onSuccess={handleChange}/> // 登录
      if (stage === 1) return <IDCardLoginView onSuccess={handleChange}/> // 身份证登录
      if (stage === 2) return <FaceCheckView /> // 人脸中间页
      if (stage === 3) return <HouseKeeperSignView /> // 签署中间页
      if (stage === 4) return <SignResult /> // 签署结果页
    }
    return (
      <Spin spinning={loading}>
        {render(stage)}
      </Spin>
    )
}

export default StewardLogin
