import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/index.scss';

window.loadRem = function () {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + "px";
}
window.addEventListener("resize", window.loadRem, false);

window.xxx = function () {
    function yyy() {
        setTimeout(() => {
            const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
        }, 100);
    }
    let input = document.querySelectorAll('input');
    for (let i = 0; i < input.length; i++) {
        input[i].removeEventListener('blur', yyy)
        input[i].addEventListener('blur', yyy)
    }
}

ReactDOM.render(<App />, document.getElementById('root'));