import React, { Component } from 'react';
import PropTypes from 'prop-types'
import './index.scss'

class ModalText extends Component {
  createTitle() {
    const { title } = this.props
    if (!title) return null
    if (typeof(title) === 'object') return title;
    if (typeof(title) === 'string') return <div className="title">{title}</div>
    return null
  }

  handleCancel() { // 关闭
    this.props.onCancel && this.props.onCancel()
  }

  handleConfirm() { // 确定
    this.props.onOk && this.props.onOk()
  }

  createFooter() {
    if (this.props.footer) return this.props.footer
    return (
      <div className="footer">
        {this.props.onCancel && <span className="left" onClick={() => {this.handleCancel()}}>{this.props.cancelText || '关闭'}</span>}
        {this.props.onOk && <span className="right" onClick={() => { this.handleConfirm() }}>{this.props.confirmText || '确定'}</span>}
      </div>
    )
  }

  render() {
    if (!this.props.visible) return null
    return (
      <div className="modal-text-container">
        <div className="modal-text-content">
          { this.createTitle() }
          <div className="body">
            { this.props.children }
          </div>
          { this.createFooter() }
        </div>
      </div>
    )
  }
}

ModalText.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ModalText