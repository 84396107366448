import React from 'react';
import './index.scss';


function Page(props) {
    return (
        props.show
            ? <div className="fadeIn animated hint-box">
                <div className="slideInUp animated hint-modle" onClick={e => e.stopPropagation()}>
                    <div> 
                        <i className="iconfont" style={{ fontSize: '1.5rem' ,color: 'rgba(78, 182, 170)' }}>&#xe602;</i>
                    </div>
                    <div className="content">
                        <div style={{ color: 'rgba(78, 182, 170)',fontSize: '.44rem'}}>
                            操作成功
                        </div>
                        <div>
                            <span style={{ color: 'rgba(78, 182, 170)'}}>{props.time}s</span> 后自动关闭
                        </div>
                    </div>
                </div>
            </div>
            : null
    );
}

export default Page