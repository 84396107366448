/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-17 17:47:34
 * @LastEditTime: 2022-02-07 20:52:04
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - 账号密码登录
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/LoginFormView.jsx
 */
import React, { useState, useRef } from "react";
import { isPhone } from "../../../util/validate";
import { Link } from "react-router-dom";
import "../../Login/index.scss";
import { showToast } from "../../../component/Toast";
import Spin from "../../../component/Spin";
import { fetchHouseKeeperAuthLogin } from "../../../services/housekeeperAuth";
import SelectedMerchant from "./SelectedMerchant";
import Cookies from "js-cookie";

const StewardLoginForm = (props) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [slectTreaty, setSlectTreaty] = useState(false);

    const SelectedMerchantRef = useRef()


    const postSubmit = (merchantId) => {
        const data = { phone: formData.phone, password: formData.password, merchantId }
        setLoading(true)
        return fetchHouseKeeperAuthLogin(data).then(res => {
            return res
        }).catch(res => {
            if(res.code === 100 && Array.isArray(res.data)) {
                return SelectedMerchantRef.current.open(res.data).then(merchantId => {
                    return postSubmit(merchantId)
                })
            }
            showToast(res.message)
            return Promise.reject(res)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleInputChange = (data) => {
        setFormData({ ...formData, ...data });
    };

     

    

    const handleSubmit = () => {
        if (!isPhone(formData.phone)) return showToast("请输入正确的手机号");
        if (!formData.password) return showToast("请输入密码");
        
        if (!slectTreaty) return showToast('请阅读并同意服务协议')
        return postSubmit().then((res) => {
            console.log('===>',res)
            if (!res) return
           props.onSuccess(res.data)
           Cookies.set('loginData', res.data)
        })
    };
    return (
        <div className="StewardLoginForm login">
            <Spin spinning={loading} style={{height: '100%'}}>
                <div className="box-wrap">
                    <div className="title">管家登录</div>
                    <div>
                        <label className="form-label">
                            <div className="form-icon">
                                <i className="iconfont">&#xe642;</i>
                            </div>
                            <input
                                value={formData.phone}
                                onChange={(e) => {
                                    handleInputChange({ phone: e.target.value });
                                }}
                                type="number"
                                className="form-input tran3"
                                placeholder="请输入手机号码"
                            />
                        </label>
                        <label className="form-label">
                            <div className="form-icon">
                                <i className="iconfont">&#xe642;</i>
                            </div>
                            <input
                                value={formData.password}
                                onChange={(e) => {
                                    handleInputChange({ password: e.target.value });
                                }}
                                type="password"
                                className="form-input tran3"
                                placeholder="请输入密码"
                            />
                        </label>
                    
                        <label
                            className="form-label selcet-treaty"
                            onClick={() => setSlectTreaty(!slectTreaty)}
                        >
                            {slectTreaty ? (
                                <i className="iconfont">&#xe728;</i>
                            ) : (
                                <i className="iconfont">&#xe615;</i>
                            )}

                            <div style={{ flex: "0 0 10px" }}></div>
                            <div className="form-custom">
                                <span>同意</span>
                                <Link to="/agreement" className="treaty">
                                    《服务协议》
                                </Link>
                                <span>和</span>
                                <Link to="/numberCertificate" className="treaty">
                                    《数字证书使用协议》
                                </Link>
                            </div>
                        </label>
                        <button
                            type="button"
                            className={`form-btn tran3 ${slectTreaty ? "" : "disabled"}`}
                            onClick={handleSubmit}
                        >
                            登录
                        </button>
                    </div>
                </div>
            </Spin>

            <SelectedMerchant ref={SelectedMerchantRef} />
        </div>
    );
};

export default StewardLoginForm;