/*
 * @Author: zhao - 🍉
 * @Date: 2021-08-24 09:46:34
 * @LastEditTime: 2021-08-31 11:51:41
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/page/MoreBill/index.js
 */
import React, { useState, useEffect } from 'react';
import { postFrom, config } from '../../axios';
import Toast from '../../component/Toast';
import './index.scss';
import { util_browser } from '../../util';
import BillListView from "../../container/BillListView";

function Page(props) {
 

 
   
    document.title = '更多账单';
    const [message, setMessage] = useState({ show: false, exp: '' });
    const [billData, setBillData] = useState([]);
    const { houseId, contractId } = props.match.params;

    useEffect(() => {
        setMessage({ show: true, exp: '加载中' });
        postFrom({
            url: config.API_bill_findBill,
            data: { houseId, contractId }
        }).then(res => {
            setMessage({ show: false, exp: '' });
            setBillData(res.data.bills);
        }).catch(err => {
            setMessage({ show: true, exp: err.message || '加载失败' });
        })
    }, []);


     // 获取当前合同的是否为建融
     function getCurrentUserIsJR(list) {
        let result = true
        list.forEach(e => {
            if (e.isJh !==1) {
                result = false
            }
        })
        return result
    }

    // 点击支付
    function handlePay(data) {
        localStorage.setItem('pay', JSON.stringify(data))

        if(getCurrentUserIsJR(data) || util_browser().weixin){
            props.history.push('/pay/ys');
        } else {
            showMessage('支付功能目前仅支持微信浏览器')
        }
    };

    // 弹窗
    function showMessage(message) {
        setMessage({ show: true, exp: message || '获取信息失败' });
        setTimeout(() => {
            setMessage({ show: false, exp: '' });
        }, 2000);
    };

    return (
        <div className="more-bill-wrap">
           <BillListView billData={billData} onPay={handlePay} />
            <Toast
                show={message.show}
                content={<div>{message.exp}</div>}
            />
        </div>

    )

}

export default Page