import React, { useState, useEffect } from 'react';
import { postFrom, config } from '../../axios';
import Cookies from 'js-cookie';
import Toast from '../../component/Toast';
import './index.scss';
import { util_browser, util_math_calc } from '../../util';
import {getPayUrl_HSB} from "../../axios/configs";

function PayBill(props) {

    let _result = false;
    let _hsb_result= false;
    let _payBillList = localStorage.getItem('pay') ? JSON.parse(localStorage.getItem('pay')) : []
    _payBillList.forEach(e => {
        if (e.isJh ===1) {
            _result = false
        }
        if(e.isHsb === 1){
            _hsb_result=true;
            _result=false;
        }
    })

    const [payType, setPayType] = useState(_hsb_result?'惠市宝支付':(_result ?'数币人民币':'微信支付'));
    const [payBillList, setPayBillList] = useState([]);
    const [toastMsg, setToastMsg] = useState({ show: false, showMessage: '' });
    const [discountCoupon, setDiscountCoupon] = useState({})

    useEffect(() => {
        window.xxx();
        let payBillList = localStorage.getItem('pay') ? JSON.parse(localStorage.getItem('pay')) : []
        setPayBillList(payBillList)
    }, [])
    function postCounponDisCountGet() {
        const data = {
         billIdsStr: getPayBillIds(),
         payType :payType === '微信支付' ? 3 : 7
        }
       postFrom({ url: config.API_customer_getDiscount, data }).then(res => {
           setDiscountCoupon(res.data || {})
       })
   }
    useEffect(() => {
        if (payType && payBillList) {
            postCounponDisCountGet()
        }
    }, [payType, payBillList])

    let loginData = Cookies.get('loginData');
    loginData = loginData ? JSON.parse(loginData) : {};


    document.title = '支付';


    // 弹窗提示器
    function showToast(msg, time = 2000) {
        setToastMsg({show: true, showMessage: msg});
        setTimeout(() => {
            setToastMsg({show: false, showMessage: ''});
        }, time);
    }

    // 计算支付订单累加金额
    function sumBills() {
        let result = 0
        payBillList.forEach(e => {
            result = util_math_calc.add(result, e.billMoney)
        })
        if (discountCoupon.creditAmount) {
            result = util_math_calc.minus(result, discountCoupon.creditAmount)
        }
        return result
    }

    // 获取当前合同的是否为建融
    function getCurrentUserIsJR() {
        let result = false
        // payBillList.forEach(e => {
        //     if (e.isJh ===1 && !(e.isHsb === 1)) {
        //         result = true
        //         console.log(e)
        //     }
        //
        // })
        return result
    }

    function getCurrentUserIsHsb() {
        let result = false
        payBillList.forEach(e => {
            if (e.isHsb ===1) {
                result = true
                console.log(e)
            }

        })
        return result
    }

    function getPayBillIds() {
        return payBillList.map(e => e.id).join(',')
    }

    // 更换支付方式
    function handlePayTypeChange(e) {
        setPayType(e);
    };

    function handleNavTips(e) {
        e.stopPropagation()
        props.history.push('/pay/tips')
    }



    // 点击提交支付
    function handleSubmit() {

      if(payType==='微信支付') {
          if (util_browser().weixin) {
              // 提交
              setToastMsg({show: true, showMessage: '加载中...'});
              if (typeof window.WeixinJSBridge === "undefined") {
                  document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
              } else {
                  onBridgeReady();
              }
          } else {
            showToast('支付功能目前仅支持微信浏览器');
          }
      }else if(payType==='数币人民币'){
          // 提交
          setToastMsg({show: true, showMessage: '加载中...'});

          getJHSZRMBPayUrl();

      }else{
          // 提交
          setToastMsg({show: true, showMessage: '加载中...'});

          getJHHsbPayUrl();
      }

      function getJHSZRMBPayUrl() {
          postFrom({ url: config.getJsapiPayInfo_SZRMB, data: { billIdsStr: getPayBillIds(), discountCouponLogId: discountCoupon.id || 0  } }).then(res => {
              // 提交
              setToastMsg({show: false,showMessage: '跳转数币支付'});
              window.location.href = res.data;
          }).catch(err => {
            showToast(err.message || '获取数币支付通道失败...')
        })
      }

        function getJHHsbPayUrl() {
            postFrom({ url: config.getPayUrl_HSB, data: { billIdsStr: getPayBillIds(), discountCouponLogId: discountCoupon.id || 0  } }).then(res => {
                setToastMsg({show: false,showMessage: '跳转惠市宝支付'});
                window.location.href = res.data;
            }).catch(err => {
                showToast(err.message || '获取惠市宝支付通道失败...')
            })
        }




        function onBridgeReady() {
            postFrom({ url: config.API_customer_getJsapiPayInfo, data: { billIdsStr: getPayBillIds(), openid: loginData.openid, discountCouponLogId: discountCoupon.id || 0 } }).then(res => {
                let jsapi_pay_info = JSON.parse(res.data);
                window.WeixinJSBridge.invoke("getBrandWCPayRequest", {
                    appId: jsapi_pay_info.appId, //公众号名称，由商户传入
                    timeStamp: jsapi_pay_info.timeStamp, //时间戳 这里随意使用了一个值
                    nonceStr: jsapi_pay_info.nonceStr, //随机串
                    package: jsapi_pay_info.package,
                    signType: jsapi_pay_info.signType, //微信签名方式:MD5
                    paySign: jsapi_pay_info.paySign //微信签名
                }, function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:fail") {
                        //支付失败
                        props.history.push('/payResult', { type: 2, parState: payBillList })
                    } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                        //支付过程中用户取消
                        showToast(res.err_desc || '支付失败')
                    } else if (res.err_msg === "get_brand_wcpay_request:ok") {
                        //支付成功
                        props.history.push('/payResult', { type: 1 })
                        localStorage.setItem('pay', '')
                    }
                });
            }).catch(err => {
                if (err.code === 999) {
                    alert('支付申请已受理，请关闭当前页面，在公众号首页通过消息完成付款。')
                    window.WeixinJSBridge.call('closeWindow');
                    return
                }
                showToast(err.message || '获取支付参数失败...')
            })
        };

    };

    return (
        <div className="payment">
            <div className="amount">
                {payBillList.map(e => (
                    <React.Fragment>
                        <div className="item" key={e.id}>
                            <div>{e.month + '-' + e.feeName}</div>
                            <div className="money">{e.billMoney}</div>
                        </div>
                        {
                            e.id === discountCoupon.billId && (
                                <div className="item discount" key={e.id + 'discount'}>
                                    <div>{discountCoupon.couponName}</div>
                                    <div className="money">-{discountCoupon.creditAmount}</div>
                                </div>
                            )
                        }
                    </React.Fragment>
                ))}
                <div className="item sum">
                    <div>合计</div>
                    <div className="money">{sumBills()}</div>
                </div>
            </div>
            <div>
                <div className="payway">支付方式</div>
                {
                    getCurrentUserIsJR() && (
                        <label className="payList" onClick={() => handlePayTypeChange('数币人民币')}>
                            <div>
                                <img src={require('../../image/szrmb.png')} style={{    height: ".72rem",
                                    marginTop: 'auto',
                                    marginBottom: 'auto'}} alt="" />
                                <div>
                                </div>
                                <div className="tips" onClick={handleNavTips} >操作指引</div>
                                <div className="tips" style={{color: 'red', marginBottom: '20px'}}>数币支付并升级二类卡首单满25元减20元</div>
                            </div>
                            <div className={`circle-wrap ${payType === '数币人民币' ? 'active' : ''}`}>
                                <div className="circle" />
                            </div>
                        </label>
                    )
                }
                {getCurrentUserIsHsb() ? null:(<label className="payList"  onClick={() => handlePayTypeChange('微信支付')}>
                    <div className="name-wrap">
                        <i className="iconfont" style={{ color: 'rgb(107,204,3)' }}>&#xe609;</i>
                        <div className="payname">
                            <span className="first">微信支付</span>
                            <span className="second">微信安全支付</span>
                        </div>
                    </div>
                    <div className={`circle-wrap ${payType === '微信支付' ? 'active' : ''}`}>
                        <div className="circle" />
                    </div>
                </label>)}

                {getCurrentUserIsHsb() &&(<label className="payList"  onClick={() => handlePayTypeChange('惠市宝支付')}>
                    <div className="name-wrap">
                        {/*<i className="iconfont" style={{ color: 'rgb(107,204,3)' }}>&#xe609;</i>*/}
                        <svg t="1644301231769" className="icon" viewBox="0 0 1325 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="1816" data-spm-anchor-id="a313x.7781069.0.i1"
                             width="128" height="128">
                            <path
                                d="M1043.576471 537.6s10.541176-1.505882 15.058823 1.505882c6.023529 3.011765 4.517647 4.517647 4.517647 7.529412C1055.623529 761.976471 874.917647 933.647059 659.576471 933.647059 441.223529 933.647059 256 755.952941 256 533.082353c0-197.270588 143.058824-362.917647 337.317647-396.047059 21.082353-3.011765 78.305882-7.529412 129.505882 43.670588l242.447059 239.435294-84.329412 84.329412s-12.047059 9.035294-19.57647 7.529412c-7.529412-1.505882-15.058824-10.541176-15.058824-10.541176l-180.705882-179.2c-3.011765-3.011765-7.529412-3.011765-10.541176 0L450.258824 525.552941c-3.011765 3.011765-3.011765 7.529412 0 10.541177L656.564706 737.882353c3.011765 3.011765 7.529412 3.011765 10.541176 0l194.258824-189.741177s9.035294-7.529412 12.047059-9.035294c4.517647-1.505882 15.058824-1.505882 15.058823-1.505882h155.105883z m-256-364.423529c-60.235294-67.764706-115.952941-69.270588-115.952942-69.270589s138.541176-58.729412 260.517647 63.247059l129.505883 129.505883s9.035294 7.529412 7.529412 12.047058c-3.011765 9.035294-7.529412 18.070588-7.529412 18.070589l-60.235294 57.223529-213.835294-210.823529z"
                                fill="#004F9C" p-id="1817"></path>
                        </svg>
                        <div className="payname">
                            <span className="first">惠市宝</span>
                            <span className="second">建行惠市宝支付</span>
                        </div>
                    </div>
                    <div className={`circle-wrap ${payType === '惠市宝支付' ? 'active' : ''}`}>
                        <div className="circle" />
                    </div>
                </label>)}


                <button className="form-btn tran3" onClick={handleSubmit}>确定</button>
            </div>

            <Toast
                show={toastMsg.show}
                content={<div>{toastMsg.showMessage}</div>}
            />
        </div>
    );
}

export default PayBill
