import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { post, postFrom, config } from '../../axios';
import Toast from '../../component/Toast';
import Cookies from 'js-cookie';
import qs from 'qs';
import './index.scss';

function Page(props) {
    document.title = '登录';
    let search = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    const [phone, setPhone] = useState('');
    const [yzm, setyzm] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [slectTreaty, setSlectTreaty] = useState(true);
    const [codeCount, setCodeCount] = useState(60);
    const [show, setShow] = useState(false);
    const [openid, setOpenid] = useState('');
    const [loginId, setLoginId] = useState(true);

    useEffect(() => {
        let timer = null;

        if (codeCount < 60 && codeCount > -1) {
            if (codeCount === 0) {
                setCodeCount(60);
            } else {
                timer = setTimeout(() => {
                    setCodeCount(codeCount - 1);
                }, 1000)
            }
        }

        return () => {
            timer && clearTimeout(timer)
        }
    }, [codeCount]);

    useEffect(() => {
        loginByWeixinCode()
        window.xxx();
    }, [loginByWeixinCode]);

    function handlePhone(value) { //写入手机号
        if (value.length > 11) {
            return
        }
        setPhone(value);
    }
    function handleYzm(value) { //写入验证码
        setyzm(value);
    }
    function handleChangeTreaty() { //改变选中
        setSlectTreaty(!slectTreaty);
    }
    function sendCode(e) { //发送验证码
        e.preventDefault();
        if (/^1[3456789]\d{9}$/.test(phone)) {
            setCodeCount(codeCount - 1);
            const data = { 'phone': phone };
            handleSendVerifyCode(data)
        } else {
            toastMsg('请填写正确的手机号码');
        }
    }
    function handleSendVerifyCode(data) {
        postFrom({
            url: config.API_open_home_sendVerifyCode,
            data
        }).then(res => {
            if (res.code === 0) {
                toastMsg('发送成功');
            }
        }).catch(err => {
            toastMsg(err.message);
        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (/^1[3456789]\d{9}$/.test(phone) && yzm.length && slectTreaty) {
            const state = search.state === 'state' || (search.state && search.state.length === 11) ? 1 : search.state
            const data = {
                phone: +phone,
                verifyCode: +yzm,
                loginFrom: search.state ? Number(state) : Number(search.loginFrom || 1)
            }

            if (openid) {
                data.openid = openid
            }
            
            post({
                url: config.API_open_home_login,
                data
            }).then(res => {
                Cookies.set('loginData', res.data);
                if (res.code === 0) {

                    if (res.data.credentialType == 1 && res.data.credentialIdentified === false) {
                    
                        // 个人-内地身份证-未上传证件
                        props.history.push('/identity', {
                            faceIdentifyUrl: res.data.faceIdentifyUrl,
                            signableContractId: data.loginFrom == "2"?res.data.signableContractId:0
                        });
                        return false
                    }
                    
                    if (res.data.signableContractId > 0 && (data.loginFrom == "2" || res.data.loginFrom == 2) ) {
                        // 有签约合同-来自签约的
                        if (res.data.credentialIdentified === false) {
                            // 是否需要影像认证
                            props.history.push('/identity', {
                                faceIdentifyUrl: res.data.faceIdentifyUrl,
                                signableContractId: res.data.signableContractId
                            });
                        } else if (res.data.faceDetected) {
                            // 需要人脸识别
                            props.history.push('/faceAuth');
                        } else {
                            props.history.push('/bestsignResult?from=login');
                        }
                    } else {
                        props.history.replace('/bill', { alertNum: res.data.alertNum });
                    }
                }
            }).catch(err => {
                toastMsg(err.message);
            })
        } else {
            toastMsg('请输入合法参数');
        }
    }

    let weixinCode = search.code;
    function loginByWeixinCode() {
        if (weixinCode) {    //有code的话 微信调自动登录接口
            let data = {};
            data.weixinCode = weixinCode
            const state = search.state === 'state' || search.state.length === 11 ? 1 : search.state
            data.loginFrom = +(state || 1);
            
            postFrom({
                url: config.API_open_home_loginByWeixinCode,
                data,
            }).then(res => {
                Cookies.set('loginData', res.data);
                !openid && setOpenid(res.data.openid)
                if (res.data.id) {
                    if (search.state.length === 11 && search.state != res.data.phone) {
                      setLoginId(false);
                      return false
                    }
                    if (res.data.customerType == 2 && res.data.credentialType == 1 && res.data.credentialIdentified === false) {
                        // 个人-内地身份证-未上传证件
                        props.history.push('/identity', {
                            faceIdentifyUrl: res.data.faceIdentifyUrl,
                            signableContractId: res.data.signableContractId
                        });
                    }
                    if (res.data.signableContractId > 0 && (search.state === "2" || res.data.loginFrom == 2)) {
                        // 有签约合同-来自签约的
                        if (res.data.credentialIdentified === false) {
                            // 是否需要影像认证
                            props.history.push('/identity', {
                                faceIdentifyUrl: res.data.faceIdentifyUrl,
                                signableContractId: res.data.signableContractId
                            });
                        } else if (res.data.faceDetected) {
                            // 需要人脸识别
                            props.history.push('/faceAuth');
                        } else {
                            props.history.push('/bestsignResult?from=login');
                        }
                    } else {
                        props.history.replace('/bill', { alertNum: res.data.alertNum });
                    }
                } else {
                    post({
                      url: config.API_customer_login,
                      data: { phone: search.state, loginFrom: 1 }
                    }).then(res => {
                      if (res.data.id) {
                        Cookies.set('loginData', res.data);
                        if (search.state.length === 11 && search.state != res.data.phone) {
                          setLoginId(false);
                        }
                        if (res.data.customerType == 2 && res.data.credentialType == 1 && res.data.credentialIdentified === false) {
                            // 个人-内地身份证-未上传证件
                            props.history.push('/identity', {
                                faceIdentifyUrl: res.data.faceIdentifyUrl,
                                signableContractId: res.data.signableContractId
                            });
                        }
                        if (res.data.signableContractId > 0 && (search.state === "2" || res.data.loginFrom == 2)) {
                            // 有签约合同-来自签约的
                            if (res.data.credentialIdentified === false) {
                                // 是否需要影像认证
                                props.history.push('/identity', {
                                    faceIdentifyUrl: res.data.faceIdentifyUrl,
                                    signableContractId: res.data.signableContractId
                                });
                            } else if (res.data.faceDetected) {
                                // 需要人脸认证
                                props.history.push('/faceAuth');
                            } else {
                                props.history.push('/bestsignResult?from=login');
                            }
                        } else {
                            props.history.replace('/bill', { alertNum: res.data.alertNum });
                        }
                      } else {
                        setLoginId(false);
                      }
                    }).catch(err => {
                      setLoginId(false);
                    })
                }
            }).catch(err => {
               // setShow(true);
               // setErrorMsg(err.message);
            })
        } else {
            return
        }
    }

    function toastMsg(msg) {
        setShow(true);
        setErrorMsg(msg);
        setTimeout(() => {
            setShow(false);
        }, 2000)
    }

    if (weixinCode && loginId) { //如果有是微信自动登录 并且已经绑定过了
        return <div className="login-loading">
            {
                show
                    ? <Toast
                        show={show}
                        content={<div>{errorMsg}</div>}
                    />
                    : <i className="iconfont">&#xe6ad;</i>
            }
        </div>
    }

    return (
        <div className="login">
            <div className="box-wrap">
                <div className="title">快捷登录</div>
                <form>
                    <label className="form-label">
                        <div className="form-icon">
                            <i className="iconfont">&#xe642;</i>
                        </div>
                        <input value={phone} onChange={(e) => { handlePhone(e.target.value) }} type="number" className="form-input tran3" placeholder="请输入手机号码" />
                    </label>
                    <label className="form-label">
                        <div className="form-icon">
                            <i className="iconfont">&#xe623;</i>
                        </div>
                        <input value={yzm} onChange={(e) => { handleYzm(e.target.value) }} type="number" className="form-input tran3" maxLength="6" placeholder="请输入验证码" />
                        {
                            codeCount === 60
                                ? <div className="form-getYzm" onClick={sendCode}>获取验证码</div>
                                : <div className="form-getYzm" style={{ color: '#ccc' }}>重新发送({codeCount})</div>
                        }
                    </label>
                    <label className="form-label selcet-treaty">
                        {slectTreaty ? <i onClick={handleChangeTreaty} className="iconfont">&#xe728;</i> : <i onClick={handleChangeTreaty} className="iconfont">&#xe615;</i>}
                        <div style={{ flex: '0 0 10px' }}></div>
                        <div className="form-custom">
                            <span>同意</span>
                            <Link to="/agreement" className="treaty">《服务协议》</Link>
                            <span>和</span>
                            <Link to="/numberCertificate" className="treaty">《数字证书使用协议》</Link>
                        </div>
                    </label>
                    <button className={`form-btn tran3 ${phone.length && yzm.length && slectTreaty ? "" : "disabled"}`} onClick={handleSubmit}>登录</button>
                </form>
            </div>

            <Toast
                show={show}
                content={<div>{errorMsg}</div>}
            />

            
        </div>
    );
}

export default Page