/*
 * @Author: zhao - 🍉
 * @Date: 2021-08-24 09:46:34
 * @LastEditTime: 2022-03-07 16:22:59
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/axios/index.js
 */
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import {message} from 'antd'
// import { BrowserRouter } from "react-router-dom";
import * as configs from './configs';
import { API_NAME } from '../common/config';
import { authClearUserInfo } from '../util/auth';

// const router = new BrowserRouter();
// router.history.push('/');

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    config => {
        //如果是登录、短信，则不添加头部id
        let gitignoreUrl = [
            configs.HOST + '/open/home/sendVerifyCode',
            configs.HOST + '/weixin/customer/login',
            configs.HOST + '/auth/housekeeper/login',
            configs.HOST + '/weixin/customer/loginByWeixinCode',
            configs.HOST + '/weixin/customer/getWeixinOpenid',
        ];
        let loginData = Cookies.get('loginData');
        if (loginData) {
            loginData = JSON.parse(loginData);
            config.headers.customerId = loginData.id;
            config.headers.merchantId = loginData.merchantId;
            config.headers.merchantUserId = loginData.id;
            config.headers.token = loginData.loginToken;
        } else if (gitignoreUrl.findIndex(e => e.indexOf(config.url) > -1) === -1) {
            window.location.href = '/login';
        } 
        
        
        // 如果 请求 url没加 apiname ,此处兼容
        if (config.url.indexOf(API_NAME) === -1) {
            config.url = API_NAME + config.url
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        if (response.data.code === 0)
            return response.data
        else if (response.data.code)
            return Promise.reject({ code: response.data.code,data: response.data.data, message: response.data.message });
        else
            return response.data
    },
    error => {
        if (error.response) {
            // 登录失效情况拦截
            if (error.response.status === 401) {
                // 清理token信息
                authClearUserInfo() 
                // 如果是管家登录页面,不用跳转, 租客跳回租客登录页 
                if (window.location.pathname === '/steward-login') {


                } else {
                    message.error('登录已失效', 1).then(() => {
                        window.location.href = '/login';
                    });
                }
                
            }
            return Promise.reject({ 
                code: error.response.code, 
                data: error.response.data,
                message: typeof error.response.data === 'object' ? error.response.data.message : error.response.code 
            });
        }
        return Promise.reject(error);
    }
);

/**
 * @description: 公用get请求
 * @param {url: string}   接口地址
 * @param {data: json}    接口参数
 * @param {msg: string}   接口错误提示
 * @param {headers: json} 接口请求头
 * @return: {axios: promise}
 */
export const get = function ({ url, data }) {
    return axios.get(url, data)
}
export const post = function ({ url, data, headers }) {
    return axios.post(url, data, headers)
}
export const postFrom = function ({ url, data, headers = {} }) {
    data = qs.stringify(data);
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return axios.post(url, data, { headers })
}
export const config = configs;
