/*
 * @Author: zhao - 🍉
 * @Date: 2021-08-30 09:58:03
 * @LastEditTime: 2021-09-01 20:46:30
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/util/index.js
 */
export const util_math_calc = {
  getMax(a,b) {
      const arr_a = a.toString().split('.')
      const arr_b = b.toString().split('.')
      arr_a[1] = arr_a[1] ? arr_a[1] : ''
      arr_b[1] = arr_b[1] ? arr_b[1] : ''
      const max_float = Math.max(arr_a[1].length, arr_b[1].length)
      return max_float
  },
  getInt(val,max_float) {
    const arr_val = val.toString().split('.')
    const a = arr_val[1] || ''
    let float = ''
    if (a.length < max_float) {
      float =  a.padEnd(max_float, 0)
    } else {
      float = a.substring(0, max_float) + '.' + a.substring(max_float)
    }
    return parseFloat(arr_val[0]  + float)
  },
  add(a,b) {

    const max_float = this.getMax(a,b)
    const pow =  Math.pow(10,max_float) 
    const int_a = this.getInt(a, max_float) 
    const int_b = this.getInt(b, max_float) 
    return (int_a + int_b) / pow
  },
  minus(a,b) {
    const max_float = this.getMax(a,b)
    const pow =  Math.pow(10,max_float) 
    const int_a = this.getInt(a, max_float) 
    const int_b = this.getInt(b, max_float) 
    return (int_a - int_b) / pow
  }

}

export const util_browser = () => {
  var u = navigator.userAgent
  return {         //移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, //是否iPad
      webApp: u.indexOf('Safari') === -1, //是否web应该程序，没有头部与底部
      weixin: u.match(/MicroMessenger/i) // 微信
  };
}