import React, { useState, useContext, useEffect } from 'react';
import Tips from '../../component/Tips';
import Toast from '../../component/Toast';
import Cookies from 'js-cookie';
import './index.scss';
import { post, postFrom, config } from '../../axios';
import { context } from '../../store';

function Page(props) {
    document.title = '身份确认';
    useEffect(() => {
        window.xxx();
    }, []);

    const { dispatch } = useContext(context);
    const [showTips, setShowTips] = useState(false); //提交时的确认提示
    const [toast, setToast] = useState({ show: false, message: '' }); //全屏弹窗
    let initIdentityMsg = localStorage.getItem('identity');
    if (initIdentityMsg) {
        initIdentityMsg = JSON.parse(initIdentityMsg);
    } else {
        initIdentityMsg = { zm: { url: '', attachId: 0, name: '', number: '' }, fm: { url: '', attachId: 0, authority: '' } };
    }
    const [identityMsg, setIdentityMsg] = useState(initIdentityMsg); //身份证信息
    useEffect(() => {
        localStorage.setItem('identity', JSON.stringify(identityMsg));
    }, [identityMsg])


    // file对象转换成dataurl
    function readBlobAsDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function(e) {
            callback(e.target.result, blob.size);
        };
        a.readAsDataURL(blob);
    }
    // dataurl转换成blob
    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type:mime});
    }
    // blob转换成dataUrl，并且通过canvas画布压缩重新生成新的dataUrl
        /*
        * option: {
        *     url: image url,
        *     size: image size,
        *     width: 1200,
        *     height: 900,
        *     callback: callback
        * }
        */
       function readBase64AsBlob(option) {

        var opt = {
            url: option.url,
            size: option.size,
            width: option.width || 1200,
            height: option.height || 900,
            callback: option.callback || function(url) {}
        };

        var img = new Image();
        img.src = opt.url;
        img.onload = function(){
            var canvas = document.createElement("canvas"), //创建canvas元素
                width = img.width, //确保canvas的尺寸和图片一样
                height = img.height;

            console.log('压缩前图片的尺寸大小：', width, height);
            // 根据最大尺寸 12000*900的大小，按比例重新设置图片的尺寸
            // var neww = opt.width;
            // var newh = opt.height;
            // // 当图片的宽高分别大于1200，900的情况下，在对其进行尺寸的压缩（尺寸压缩对最终size的减小有很大作用）
            // if(width > opt.width && height > opt.height) {
            //     if(height/width > opt.height/opt.width) {
            //         newh = opt.height;
            //         neww = (opt.height/height) * width;
            //     } else {
            //         newh = (opt.width/width) * height;
            //         neww = opt.width;
            //     }
            // }

            // 压缩率
            var rate = 0.6
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(img, 0, 0, width, height); //将图片绘制到canvas中
            var dataURL = canvas.toDataURL('image/jpeg', rate); //转换图片为dataURL
            opt.callback(dataURL);
        }
    }

    // 用户在浏览器本地选择上传图片

       function compressUploadImageAsClient(e, type) {
            e.preventDefault();
            let file = e.target.files[0]
            // 如果不支持H5的filereader方法，直接用原图上传
            if(typeof FileReader != 'function') {
                handleUpload(file, type)
                return;
            }
            // 将blob类型转换成DataUrl
            readBlobAsDataURL(file, function(url, size) {
                  // 拿到url类型的图片之后，通过canvas进行压缩转换
                readBase64AsBlob({
                    url: url,
                    size: size,
                    callback: function(dataUrl) {
                        // 在压缩完成的回调函数中得到最新的图片DataUrl，将其转换成服务端接口能够识别的Blob类型
                        var blob = dataURLtoBlob(dataUrl);
                        // 调用上传服务器图片的接口
                         handleUpload(blob, type,file.name)
                    }
                });
            });
    }
    function handleUpload(file, type,name) {
        setToast({ show: true, message: '上传中...' });
        console.log(file)
        let loginData = Cookies.get('loginData');
        loginData = JSON.parse(loginData);
        let param = new FormData(); //创建form对象
        param.append('file', file,name);
        param.append('attachType', type);
        param.append('rowId', loginData.id);
        param.append('tableName', 'customer');
        post({
            url: config.API_upload_uploadfile,
            data: param,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setToast({ show: false, message: '' });
            if (res.code === 0) {
                if (res.extras) {
                    if (type === 2 && res.extras.number) { //正面
                        setIdentityMsg({ ...identityMsg, zm: { url: res.data.attach_url, attachId: res.data.attach_id, name: res.extras.name, number: res.extras.number } });
                    } else if (type === 3 && res.extras.authority) { //反面
                        setIdentityMsg({ ...identityMsg, fm: { url: res.data.attach_url, attachId: res.data.attach_id, authority: res.extras.authority } });
                    } else {
                        toastMsg('证件识别失败');
                    }
                } else {
                    toastMsg('证件识别失败');
                }
            } else {
                toastMsg('上传异常');
            }
            dispatch({ type: 'addResultCode', payload: { resultCode: res.code } })
        }).catch(err => {
            toastMsg(err.message || '上传异常');
        })
    }
    function toastMsg(msg) {
        setToast({ show: true, message: msg })
        setTimeout(() => {
            setToast({ show: false, message: '' });
        }, 2000)
    }
    return (
        <div className="identity">
            <h2 className="title">上传身份证照片</h2>
            <p className="tips">上传时确保身份证边框完整，字体清晰，亮度均匀</p>
            <div className="btns">
                <div className="upload-wrap">
                    <label>
                        <div className="img-wrap">
                            <input
                                type="file"
                                accept="image/*"
                                name=""
                                id="uploadz"
                                style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                                onChange={(e) => { compressUploadImageAsClient(e, 2) }}
                                onClick={(event) => { event.target.value = null }}
                            />
                            <img src={identityMsg.zm.url ? identityMsg.zm.url : require('../../image/frontf.png')} />
                        </div>
                        <div className="upload-tips">
                            点击上传<span style={{ color: 'rgba(78, 182, 170)' }}>人像面</span>
                        </div>
                    </label>
                </div>
                <div className="upload-wrap" style={{ marginRight: 0 }}>
                    <label>
                        <div className="img-wrap">
                            <input
                                type="file"
                                accept="image/*"
                                name=""
                                id="uploadf"
                                style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                                onChange={(e) => { compressUploadImageAsClient(e, 3) }}
                                onClick={(event) => { event.target.value = null }}
                            />
                            <img src={identityMsg.fm.url ? identityMsg.fm.url : require('../../image/front.png')} />
                        </div>
                        <div className="upload-tips">
                            点击上传 <span style={{ color: 'rgba(78, 182, 170)' }}>国徽面</span>
                        </div>
                    </label>
                </div>
            </div>

            <h3 className="notice">拍摄须知</h3>

            <div className="notice-item">
                <div style={{ marginLeft: 0 }}>
                    <div className="top-icon">
                        <img src={require('../../image/bz.png')} alt="" />
                    </div>
                    <div className="bot-desc">
                        <i className="iconfont succ">&#xe60a;</i>
                        标准拍摄
                    </div>
                </div>
                <div>
                    <div className="top-icon">
                        <img src={require('../../image/bq.png')} alt="" />
                    </div>
                    <div className="bot-desc">
                        <i className="iconfont fail">&#xe6a8;</i>
                        拍摄不全
                    </div>
                </div>
                <div>
                    <div className="top-icon">
                        <img src={require('../../image/mh.png')} alt="" />
                    </div>
                    <div className="bot-desc">
                        <i className="iconfont fail">&#xe6a8;</i>
                        拍摄模糊
                    </div>
                </div>
                <div>
                    <div className="top-icon">
                        <img src={require('../../image/gxbq.png')} alt="" />
                    </div>
                    <div className="bot-desc">
                        <i className="iconfont fail">&#xe6a8;</i>
                        光线不全
                    </div>
                </div>
            </div>

            <button className="form-btn tran3" onClick={(e) => {
                e.preventDefault();
                if (identityMsg.zm.url && identityMsg.fm.url) {
                    setShowTips(true);
                } else {
                    toastMsg('请上传身份证件');
                }
            }}>确定</button>

            <Tips
                colse={() => { setShowTips(false) }}
                show={showTips}
                title="请确认身份证信息"
                content={
                    <div className="container">
                        <div>
                            <div className="left-title">姓名</div>
                            <div className="right-content" style={{ paddingLeft: '0.25rem' }}>
                                <input
                                    className="name-input"
                                    value={identityMsg.zm.name}
                                    maxLength="10"
                                    onChange={(e) => {
                                        setIdentityMsg({ ...identityMsg, zm: { ...identityMsg.zm, name: e.target.value } });
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="left-title">证件号码</div>
                            <div className="right-content">{identityMsg.zm.number}</div>
                        </div>
                        <div>
                            <p className="remarks">注：若姓名有误，可手动修改</p>
                        </div>
                    </div>
                }
                cancelText="重拍"
                confirmText="确定"
                confirmFn={() => {
                    let { state } = props.location;
                    const requestFace = !!(state && state.signableContractId)
                    postFrom({ url: config.API_customer_credentialIdentify, data: { frontAttachId: identityMsg.zm.attachId, backAttachId: identityMsg.fm.attachId, name: identityMsg.zm.name, requestFace: requestFace } }).then(res => {
                        if (state && state.signableContractId && res.data) {
                            // 需要人脸识别
                            props.history.push('/faceAuth');
                        } else if (state && state.signableContractId > 0) {
                            // 需要签约
                            props.history.push('/bestsignResult?from=login');
                        } else {
                            // 否则前往账单
                            props.history.push('/bill');
                        }
                    }).catch(err => {
                        toastMsg(err.message || '接口异常');
                    })
                }}
            />
            <Toast
                show={toast.show}
                content={<div>{toast.message}</div>}
            />
        </div >
    )
}

export default Page