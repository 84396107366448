
import React from 'react';
import moment from 'moment';
import './index.scss';
import { mapPayType } from '../../common/dict';

function Page(props) {
    const { data } = props;


    const getPayType = (key) => {
        return mapPayType.find(e => e.key === key) || {}
    }

    return (
        <div className="bill-item">
            <div className="bill-item-title">
                <div className="title-left">
                    {data.feeName}
                    {
                        data.overdueDays > 0
                            ? <span className="title-tips">已逾期</span>
                            : null
                    }
                </div>
                {
                    data.receiveStatus === 1
                        ? <span style={{ fontSize: '0.3rem' }}>待审核</span>
                        : data.receiveStatus === 2
                            ? <div className="payment-btn" onClick={(e) => { e.stopPropagation(); props.handlePay({ id: data.id, billMoney: data.billMoney,feeName:data.feeName,isJh:data.isJh }) }}>支付</div>
                            : data.receiveStatus === 3
                                ? <div className="paid"><i className="iconfont">&#xe608;</i></div>
                                : data.receiveStatus === 4
                                    ? <span style={{ fontSize: '0.3rem' }}>不收(已退租)</span>
                                    : null
                }

            </div>
            <div className="title-c8">
                账单金额：
                {
                    data.overdueDays > 0
                        ? <span className="overdue">￥{data.billMoney}</span>
                        : <span className="noOverdue">￥{data.billMoney}</span>
                }
            </div>
            <div className="title-c8">
                账单周期：
                <span className="payment-date">{moment(data.billBeginDate).format('YYYY/MM/DD')} ~ {moment(data.billEndDate).format('YYYY/MM/DD')} </span>
            </div>
            <div className="title-c8">
                应付日期：
                <span className="payment-date">{moment(data.mustPayDate).format('YYYY/MM/DD')}</span>
            </div>
            {
                (data.beginNum!=null||data.endNum!=null) ?
                    <div className="title-c8">
                        水电读数：
                        <span className="payment-date">{data.beginNum!=null?data.beginNum:'-'} ~ {data.endNum!=null?data.endNum:'-'}</span>
                    </div>
                    : null
            }
            {
                (data.unitPrice!=null) ?
                    <div className="title-c8">
                        水电单价：
                        <span className="overdue">￥{data.unitPrice}</span>
                    </div>
                    : null
            }
            {
                data.feeRemark ? 
                <div className="title-c8">
                    费用说明：
                    <span className="payment-date">{data.feeRemark}</span>
                </div>
                : null
            }
            {
                (data.discountCouponList && data.discountCouponList.length) ? 
                <React.Fragment>
                     {
                        data.discountCouponList.map(e => (
                            <div className="title-c8" style={{color:'red'}} key={e.id}>
                                <span>[{getPayType(e.payType).value}]</span>
                                <span> {e.couponName}</span>
                            </div>
                        ))
                    }
                    <div className="title-c8" >
                        减免说明：
                        <span className="payment-date">同类型优惠券只生效一次</span>
                    </div>
                   
                    

                </React.Fragment>
                : null
                
            }
           
        </div>
    )
}

export default Page