import React from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';

function Page(props) {
    return (
        <div className="tabbar-wrap-box">
            <div className="page">
                {props.children}
            </div>
            <div className="tabbar">
                <NavLink to="/bill">
                    <i className="iconfont">&#xe610;</i>
                    <span>账单</span>
                </NavLink>
                <NavLink to="/my">
                    <i className="iconfont">&#xe607;</i>
                    <span>我的</span>
                </NavLink>
            </div>
        </div>
    );
}

export default Page