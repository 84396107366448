/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-30 15:14:02
 * @LastEditTime: 2022-02-07 22:04:58
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - 二要素登录
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/IDCardLoginView.jsx
 */

import React, { useState, useRef } from "react";
import { isIdNumber } from "../../../util/validate";
import { Link } from "react-router-dom";
import "../../Login/index.scss";
import { showToast } from "../../../component/Toast";
import Spin from "../../../component/Spin";
import { fetchHouseKeeperAuthIdCardCheck } from "../../../services/housekeeperAuth";
import SelectedMerchant from "./SelectedMerchant";

const IDCardLoginView = (props) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    const SelectedMerchantRef = useRef()


    const postSubmit = () => {
        const data = { name: formData.name, identify: formData.identify }
        setLoading(true)
        return fetchHouseKeeperAuthIdCardCheck(data).then(res => {
            return res
       
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleInputChange = (data) => {
        setFormData({ ...formData, ...data });
    };

     

    

    const handleSubmit = () => {
      if (!formData.name) return showToast("请输入姓名");
      if (!formData.identify) return showToast("请输入身份证号");
        if (!isIdNumber(formData.identify)) return showToast("请输入正确的身份证号");
        
        return postSubmit().then((res) => {
            props.onSuccess()
        })
    };
    return (
        <div className="StewardLoginForm login">
            <Spin spinning={loading} style={{height: '100%'}}>
                <div className="box-wrap">
                    <div className="title">身份证信息</div>
                    <div>
                        <label className="form-label">
                            <div className="form-icon">
                                <i className="iconfont">&#xe642;</i>
                            </div>
                            <input
                                value={formData.name}
                                onChange={(e) => {
                                    handleInputChange({ name: e.target.value });
                                }}
                                className="form-input tran3"
                                placeholder="请输入姓名"
                            />
                        </label>
                        <label className="form-label">
                            <div className="form-icon">
                                <i className="iconfont">&#xe642;</i>
                            </div>
                            <input
                                value={formData.identify}
                                onChange={(e) => {
                                    handleInputChange({ identify: e.target.value });
                                }}
                                className="form-input tran3"
                                placeholder="请输入身份证号"
                            />
                        </label>
                        <button
                            type="button"
                            className={`form-btn tran3`}
                            onClick={handleSubmit}
                        >
                            二要素认证
                        </button>
                    </div>
                </div>
            </Spin>

            <SelectedMerchant ref={SelectedMerchantRef} />
        </div>
    );
};

export default IDCardLoginView