import React, { useReducer } from "react";

const initialState = {
    addBank: {
        user: '',
        bankType: '',
        bankNo: '',
        phone: '',
        slectTreaty: true
    },
    bill: {
        activeHouse: {}
    },
    user: {
        name: ''
    },
    upload: {
        resultCode: ''
    }
};
const reducer = (state, action) => {
    switch (action.type) {
        case "addBank":
            return Object.assign({}, state, { addBank: Object.assign({}, state.addBank, action.payload) });
        case "resetAddBank":
            return Object.assign({}, state, { addBank: Object.assign({}, state.addBank,initialState.addBank) });
        case "switchHouse":
            return Object.assign({}, state, { bill: Object.assign({}, state.bill, action.payload) });
        case "addUserName":
            return Object.assign({}, state, { user: Object.assign({}, state.user, action.payload) });
        case "addResultCode":
            return Object.assign({}, state, { upload: Object.assign({}, state.upload, action.payload) });
        default:
            return state;
    }
};

const context = React.createContext();

const Provider = props => {
    const [state, origin_dispatch] = useReducer(reducer, initialState);
    const dispatch = action => {
        if (typeof action === "function") {
            return action(origin_dispatch);
        }
        return origin_dispatch(action);
    };

    return (
        <context.Provider value={{ state, dispatch }}>
            {props.children}
        </context.Provider>
    );
};

export { Provider, context };