/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-25 18:33:28
 * @LastEditTime: 2022-01-25 18:39:16
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/component/Spin/index.jsx
 */
import React from 'react'
import './index.scss'

const Spin = (props) =>{ 
  return (
  <div className='spin-container' style={props.style}>
        {props.children}
        {props.spinning &&
          <div className='loading-block'>
            <div className='spin' />
          </div>
        }
    </div>
  )
}
 
export default Spin
