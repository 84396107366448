/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-17 18:12:14
 * @LastEditTime: 2022-01-30 15:18:15
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/util/validate.js
 */

export const isPhone = (phone) => {
  const reg = /1(3|4|5|6|7|8|9)(\d){9}/g
  return reg.test(phone)
}

/**
 * 检验身份证(只判断了长度)
 *
 * @export
 * @param {string} [id='']
 * @returns {boolean}
 */
 export function isIdNumber(id = '') {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(id)
}