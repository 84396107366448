import React, { useState, useEffect } from 'react';
import './index.scss';

function Page(props) {
    useEffect(() => {
        window.xxx();
    }, [])
    const { from } = props.location.state;
    if (from === 'addBank') {
        document.title = '添加银行卡';
    } else if (from === 'pay') {
        document.title = '支付验证';
    }

    const [yzm, setyzm] = useState('');
    const [codeCount, setCodeCount] = useState(60);
    useEffect(() => {
        if (codeCount < 60 && codeCount > -1) {
            if (codeCount === 0) {
                setCodeCount(60);
            } else {
                setTimeout(() => {
                    setCodeCount(codeCount - 1);
                }, 1000)
            }
        }
    }, [codeCount]);

    function sendCode(e) { //发送验证码
        e.preventDefault();
        setCodeCount(codeCount - 1);
    }

    return (
        <div className="add-bank">
            <div className="exp-txt">已向<span>135****1545</span>发送短信验证码</div>
            <form className="form">
                <label className="form-label form-label2">
                    <input style={{ paddingLeft: '.4rem' }} value={yzm} onChange={(e) => { setyzm(e.target.value) }} className="form-input tran3" maxLength="6" placeholder="请输入验证码" />
                    {
                        codeCount === 60
                            ? <div className="form-getYzm" onClick={sendCode}>获取验证码</div>
                            : <div className="form-getYzm" style={{ color: '#ccc' }}>重新发送({codeCount})</div>
                    }
                </label>
                <button className={`form-btn tran3 ${yzm.length ? "" : "disabled"}`} onClick={(e) => {
                    e.preventDefault();
                    if (yzm.length) {
                        if (from === 'addBank') {
                            props.history.push('/bindResult', { type: 1 })
                        } else if (from === 'pay') {
                            props.history.push('/payResult', { type: 1 })
                        }

                    }
                }}>确定</button>
            </form>
        </div>
    );
}

export default Page