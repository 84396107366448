import React from 'react'
import PropTypes from "prop-types";
import './index.scss';
class Modal extends React.Component{
    createTitle () {
        if (!this.props.title) {
            return null
        }
        if(typeof(this.props.title) === 'object') {
            return this.props.title
        }
        if(typeof(this.props.title) === 'string') {
            return <div className="title">{this.props.title}</div>
        }
        return null
    }
    handleCancel() {
        this.props.onCancel && this.props.onCancel()
    }
    handleConfirm() {
        this.props.onOk && this.props.onOk()
    }
    createFooter () {
        if(this.props.footer) {
            return this.props.footer
        }
        
        return (
            <div className="footer">
                {this.props.onCancel && <span className="left" onClick={() => {this.handleCancel()}}>{this.props.cancelText || '关闭'}</span>}
                {this.props.onOk && <span className="right" onClick={() => { this.handleConfirm() }}>{this.props.confirmText || '确定'}</span>}
            </div>

        )
    }
    render(){
        if (!this.props.visible) {
            return null
        }
        return (
            <div className="modal-container">
                <div className="body">
                    {this.createTitle()}
                    <div className="scroll-block">
                        {this.props.children}
                    </div>
                    {this.createFooter()}
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),

    onOk: PropTypes.func,
    onCancel: PropTypes.func,
}
export default Modal