import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { context } from '../../store';
import { post, config } from '../../axios';
import Cookies from 'js-cookie';
import './index.scss';

function Page(props) {
    document.title = '添加银行卡';
    useEffect(() => {
        window.xxx();
    }, [])

    const { state, dispatch } = useContext(context);
    const { addBank } = state;

    function handleBankNo(e) {
        dispatch({ type: 'addBank', payload: { bankNo: e.target.value } })
    }
    function handlePhone(e) {
        dispatch({ type: 'addBank', payload: { phone: e.target.value } })
    }
    function handleSlectTreaty(e) {
        dispatch({ type: 'addBank', payload: { slectTreaty: !addBank.slectTreaty } })
    }

    function selectBank(value) {
        switch (value) {
            case "建设银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(14, 52, 132)' }}>&#xe63b;</i>
                        {value}
                    </span>
                )
            case "农业银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(0, 145, 116)' }}>&#xe62b;</i>
                        {value}
                    </span>
                )
            case "工商银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(198, 0, 11)' }}>&#xe660;</i>
                        {value}
                    </span>
                )
            case "平安银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(236, 104, 27)', fontSize: '.3rem' }}>&#xe983;</i>
                        {value}
                    </span>
                )
            case "招商银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(199, 22, 46)' }}>&#xe621;</i>
                        {value}
                    </span>
                )
            case "邮政储蓄银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(0, 125, 62)' }}>&#xe657;</i>
                        {value}
                    </span>
                )
            case "中国银行":
                return (
                    <span>
                        <i className="iconfont m-r" style={{ color: 'rgb(175, 36, 52)' }}>&#xe61f;</i>
                        {value}
                    </span>
                )
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        console.log(addBank);
        const data = {
            'bank': addBank.bankType,
            'cardNumber': addBank.bankNo,
            "cardType": 2,
            "phone": addBank.phone,
            "status": 1
        }

        if (addBank.bankType.length && addBank.bankNo.length && addBank.phone.length && addBank.slectTreaty) {
            post({
                url: config.API_bank_createBank,
                data
            }).then(res => {
                if (res.code === 0) {
                    props.history.push({
                        pathname: '/vCode',
                        state: { from: 'addBank' }
                    });
                }
                console.log(res);
            }).catch(err => {
                console.log(err)
            })

        }
    }

    return (
        <div className="add-bank">
            <form className="form">
                <label className="form-label form-label2">
                    <div className="form-icon">持卡人</div>
                    <input maxLength={5} value={Cookies.get('username')} className="form-input tran3" placeholder="请输入" readOnly />
                </label>
                <label className="form-label form-label2">
                    <div className="form-icon">所属银行</div>
                    <div onClick={() => { props.history.push('/addBankSelect') }} className="form-input slect-bank" style={{ color: addBank.bankType ? '#000' : '#ccc' }}>
                        {addBank.bankType ?
                            selectBank(addBank.bankType)
                            : '请选择'}<i className="iconfont">&#xe632;</i>
                    </div>
                </label>
                <label className="form-label form-label2">
                    <div className="form-icon">银行卡号</div>
                    <input type="number" value={addBank.bankNo} onChange={handleBankNo} className="form-input tran3" placeholder="请输入" />
                </label>
                <label className="form-label form-label2">
                    <div className="form-icon">手机号码</div>
                    <input type="number" value={addBank.phone} onChange={handlePhone} className="form-input tran3" placeholder="银行预留手机号码" />
                </label>
                <label className="form-label selcet-treaty">
                    {addBank.slectTreaty ? <i onClick={handleSlectTreaty} className="iconfont">&#xe728;</i> : <i onClick={handleSlectTreaty} className="iconfont">&#xe615;</i>}
                    <div style={{ flex: '0 0 10px' }}></div>
                    <div className="form-custom">同意<Link to="/xieyi" className="treaty">《服务协议》</Link></div>
                </label>
                <button className={`form-btn tran3 ${addBank.bankType.length && addBank.bankNo.length && /^1[3456789]\d{9}$/.test(addBank.phone) && addBank.slectTreaty ? "" : "disabled"}`} onClick={handleSubmit}>确定</button>
            </form>
        </div>
    );
}

export default Page