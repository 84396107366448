/*
 * @Author: zhao - 🍉
 * @Date: 2022-02-07 20:58:48
 * @LastEditTime: 2022-02-07 21:38:17
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - 签署页面
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/SignView.jsx
 */
import React, { useState, useEffect } from "react";
import { fetchHouseKeeperAuthSignLinkGet } from "../../../services/housekeeperAuth";
import signSrc from "../../../image/sign-check.png";
import  "../index.scss";
const HouseKeeperSignView = () => {
  const [signLink, setSignLink] = useState(null);
  useEffect(() => {
    postSignLinkGet()
  },[])


  const postSignLinkGet = () => {
    fetchHouseKeeperAuthSignLinkGet().then(res => {
      if (!res.data) return
      const data = JSON.parse(res.data)
      setSignLink(data.shortUrl || data.longUrl)
    })
  }
  return (
    <div className='HouseKeeperSignView'>
      <div className="h1">合同签署</div>
      <img src={signSrc} alt="" style={{width: '70%',marginTop: '.4rem'}} />
      <div className='h2'>合同签署</div>
      {/* <div className='h2'>以便于准确识别个人信息</div> */}
      <a href={signLink}><button className={`form-btn tran3`} >开始签署</button></a>
      <button className={`form-btn tran3 sub`} >暂不签署</button>
      
    </div>
  )
}

export default HouseKeeperSignView