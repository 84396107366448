import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TabbarWrap from '../../component/TabbarWrap';
import { post, config, postFrom } from '../../axios';
import { context } from '../../store';
import Cookies from 'js-cookie';
import './index.scss';
import Toast from '../../component/Toast';

function Page(props) {
    document.title = '我的';
    const { dispatch } = useContext(context);

    const initMenuList = [
        // { icon: <i className="iconfont">&#xe767;</i>, title: '银行卡', to: '/bank' },
        // { icon: <i className="iconfont">&#xe705;</i>, title: '关于我们', to: '/about' },
        // { icon: <i className="iconfont">&#xe62a;</i>, title: 'F&Q', to: '/faq' }
    ];

    const [errorMsg, setErrorMsg] = useState({ show: false, msg: '' });
    const [personalData, setPersonalData] = useState({});
    const [menuList] = useState(initMenuList);
    useEffect(() => {
        post({ url: config.API_personal_findCustomer }).then(res => {
            setPersonalData(res.data);
            Cookies.set('username', res.data.credentialName);
            dispatch({ type: 'addUserName', payload: { name: res.data.credentialName } })
        }).catch(err => {
            setErrorMsg({ show: true, msg: err.message });
            setTimeout(() => {
                setErrorMsg({ show: false, msg: '' });
            }, 2000);
        })
    }, []);
    const loginData = Cookies.get('loginData');
    var logo = ''
    try {
        logo = JSON.parse(loginData).weixinLogo
    } catch (error) {

    }

    return (
        <TabbarWrap>
            <div className="my">
                {
                    personalData ?
                        <div className="head">
                            <img className="portrait" src={logo} alt="头像" />
                            <div className="message">
                                <div className="msg-txt-box">
                                    <span className="txt1">{personalData.credentialName}</span>
                                    {
                                        personalData.realNameIdentified ?
                                            <span className="txt2 tag">
                                                <i className="iconfont icon">&#xe61e;</i><span className="txt2-c">已实名</span>
                                            </span>
                                            : null
                                    }
                                    {
                                        personalData.credentialIdentified ?
                                            <span className="txt2 tag">
                                                <i className="iconfont icon">&#xe694;</i><span className="txt2-c">证件已认证</span>
                                            </span>
                                            : null
                                    }
                                </div>
                                <div className="txt3">{personalData.phone}</div>
                            </div>
                        </div>
                        : null

                }
                <ul className="menu-list">
                    {
                        menuList.map((item, index) => {
                            return <li key={index}>
                                <Link className="menu-item" to={item.to}>
                                    <span className="left-item">
                                        {item.icon}
                                        <span className="title">{item.title}</span>
                                    </span>
                                    <i className="iconfont right-item">&#xe632;</i>
                                </Link>
                            </li>
                        })
                    }
                </ul>
                <button
                    className="form-btn"
                    onClick={() => {
                        let loginData = Cookies.get('loginData');
                        if (loginData) {
                            setErrorMsg({ show: true, msg: '退出中' });
                            postFrom({ url: config.API_customer_logout, data: { customerId: JSON.parse(loginData).id } }).then(res => {
                                Cookies.remove('loginData');
                                props.history.push('/login');
                            }).catch(err => {
                                setErrorMsg({ show: true, msg: err.message });
                                setTimeout(() => {
                                    setErrorMsg({ show: false, msg: '' });
                                }, 2000);
                            })
                        } else {
                            // Cookies.remove('loginData');
                            props.history.push('/login');
                        }
                    }}
                >退出</button>
                <Toast
                    show={errorMsg.show}
                    content={<div>{errorMsg.msg}</div>}
                />
            </div>
        </TabbarWrap>
    );
}

export default Page