import React, { useEffect, useState } from 'react';
import './index.scss';

function Page(props) {
    document.title = '绑定结果';
    const { type, parState } = props.location.state;
    const [codeCount, setCodeCount] = useState(3);

    useEffect(() => {
        let timer = null;
        if (codeCount <= 3 && codeCount > -1 && type === 1) {
            if (codeCount === 0) {
                if (type === 1) {
                    props.history.push('/bill');
                }
            } else {
                timer = setTimeout(() => {
                    setCodeCount(codeCount - 1);
                }, 1000)
            }
        }
        return () => {
            timer && clearTimeout(timer)
        }
    }, [codeCount]);

    return (
        <div className="bind-result">
            {
                type === 1
                    ? <div>
                        <div className="succ"><i className="iconfont">&#xe602;</i></div>
                        <div className="">支付成功</div>
                        <div className="count-down">跳转倒计时&#8194;<span>{codeCount}s</span></div>
                    </div>
                    : type === 2
                        ? <div>
                            <div className="fail"><i className="iconfont">&#xe605;</i></div>
                            <div className="">支付失败</div>
                            <button className="form-btn tran3" onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/pay/ys', parState)
                            }}>重新支付</button>
                        </div>
                        : null
            }
        </div>
    );
}

export default Page