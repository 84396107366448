/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-24 23:02:57
 * @LastEditTime: 2022-02-07 18:56:28
 * @LastEditors: zhao - 🍉
 * @Description: 管家登录 - 选择商户
 * @FilePath: /zhzj-h5/src/page/StewardLogin/components/SelectedMerchant.jsx
 */
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useRef } from "react";
import Modal from "../../../component/Modal";
import { showToast } from "../../../component/Toast";
import "../index.scss"

const SelectedMerchant = (props, ref) => {
  const [visible, setVisible] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const [selected, setSelected] = useState(null);
  const monitor = useRef()
  useImperativeHandle(ref,() => ({
    open
  }))

  const open = (list) => {
    setVisible(true)
    setMerchantList(list)
    return new Promise((resolve,reject) => {
      monitor.current = resolve
    })
  }

  const handleOk = (e) => {
    if (!selected) return showToast('请选择商户')
    setVisible(false)

    monitor.current(selected)
  }

  const handleSelect = (e) => {
    setSelected(e)
  }

  return (
    <Modal visible={visible} title="选择商户" onOk={handleOk}>
      <div className="merchant-list">
        {merchantList.map(e => {
          return <div
              key={e.id}
              className={"merchant-item"}
              onClick={() => handleSelect(e.id)}
            >
              <div>{e.merchantName}·{e.name}</div>
              <div className={"app-radio" + (selected === e.id ? ' checked' : '')}></div>
            </div>
        })}
      </div>
    </Modal>

  )
}

export default forwardRef(SelectedMerchant)
