import React, { useEffect, useState } from "react";
import BillItem from '../../component/BillItem';
import { util_math_calc } from "../../util";
import "./index.scss";
function BillList(props) {
  const [selectOrder, setSelectOrder] = useState([])
  const billData = props.billData || []
  const handlePay = props.onPay

  // 账单列表发生变化 重置选中项
  useEffect(() => {
    setSelectOrder([])
  }, [billData])

  // 计算账单总价
  function sumBills() {
    let result = 0
    selectOrder.forEach(e => {
      result = util_math_calc.add(result, e.billMoney)
    })
    return result
  }

  // 是否选中该账单
  function isSelected(bill) {
    return !!selectOrder.find(e => e.id === bill.id)
  }

  // 选中账单
  function handleBillSelect(bill) {
    if (bill.receiveStatus !== 2) return // 非支付状态
    const is = isSelected(bill)
    if (is) {
      setSelectOrder(selectOrder.filter(e => e.id !== bill.id))
    } else {
      setSelectOrder([...selectOrder, bill])
    }
  }

  const parseMonth = (date) => {
    return  new Date(date).getFullYear() + '年' + (new Date(date).getMonth() + 1) + '月'
  }

  // 账单按月份拆分
  const formatBillData = (bill = []) => { 
    const map = {}
    bill.forEach(e => {
        const month = parseMonth(e.mustPayDate)
        e.month = month
        if (map[month]) {
            map[month].push(e)
        } else {
            map[month] = [e]
        }
    })
    return Object.keys(map).map(month => ({
        month,
        list: map[month]
    }))
  }
  return (
    <div>
      <div className="bill-item-wrap">
        {
          formatBillData(billData).map((e) => (
            <div className="bill-box" key={e.month}>
              <div className="header">
                <span className="month">{e.month}</span>
              </div>
              <div className="bill-list">
                {
                  e.list.map((item) => (
                    <div className="bill-item-radio-wrap" key={item.id} onClick={() => handleBillSelect(item)}>
                      {item.receiveStatus === 2 && (
                        <div className="radio">
                          {isSelected(item) && <span className="checked"></span>}
                        </div>
                      )}
                      <BillItem
                        data={item}
                        key={item.id}
                        handlePay={() => handlePay([item])}
                      ></BillItem>

                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>

      {/* 批量支付 */}
      {selectOrder.length > 0 && (
        <div className="multi-pay-box">

          <div className="list">
            <div className="scroll-wrap">
              {selectOrder.map(e => (
                <div className="item" key={e.id}>
                  <div className="month">{e.month + '-' + e.feeName}</div>
                  <div>{e.billMoney}元</div>
                </div>
              ))}
            </div>
          </div>
          <div className="footer">
            <div className="left"> 总计：{sumBills()}元</div>
            <button className="pay-btn" onClick={() => handlePay(selectOrder)}>批量支付</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default BillList