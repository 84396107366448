import { post } from "../axios";

/*
 * @Author: zhao - 🍉
 * @Date: 2022-01-19 17:31:30
 * @LastEditTime: 2022-02-07 20:41:43
 * @LastEditors: zhao - 🍉
 * @Description: 管家认证
 * @FilePath: /zhzj-h5/src/services/housekeeperAuth.js
 */

/**
 * 获取人脸链接
 *
 * @export
 * @param {-} data
 * @returns Promise<AxiosResponse>
 */
export function fetchHouseKeeperAuthFaceLinkGet (data) {
  return post({
    url: '/auth/housekeeper/getFaceIdentityUrl',
    data
  })
}

/**
 * 获取管家的电子合同签约地址
 *
 * @export
 * @param {-} data
 * @returns Promise<AxiosResponse>
 */
export function fetchHouseKeeperAuthSignLinkGet (data) {
  return post({
    url: '/auth/housekeeper/getSignUrl',
    data
  })
}

/**
 * 管家帐号登录
 *
 * @export
 * @param {{ merchantId: 0, password: string, phone: string }} data
 * @returns Promise<AxiosResponse>
 */
export function fetchHouseKeeperAuthLogin (data) {
  return post({
    url: '/auth/housekeeper/login',
    data
  })
}

/**
 * 管家二要素认证
 *
 * @export
 * @param {{ identify: string, name: string }} data
 * @returns Promise<AxiosResponse>
 */
export function fetchHouseKeeperAuthIdCardCheck (data) {
  return post({
    url: '/auth/housekeeper/personalIdentity2',
    data
  })
}

/**
 * 获取当前的流程步骤
 *
 * @export
 * @param {-} data
 * @returns Promise<AxiosResponse>
 */
export function fetchHouseKeeperAuthStageGet (data) {
  return post({
    url: '/auth/housekeeper/getFlowStage',
    data
  })
}