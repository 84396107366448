/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-02 18:10:12
 * @LastEditTime: 2021-09-02 18:10:12
 * @LastEditors: zhao - 🍉
 * @Description: 
 * @FilePath: /zhzj-h5/src/common/dict.js
 */

export const mapPayType = [
  { key: 3, value: '微信支付' },
  { key: 7, value: '数字人民币' },
  { key: 8, value: '惠市宝支付' },
]