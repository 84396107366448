import React, { useContext, useEffect, useState } from 'react';
import { context } from '../../store';
import './index.scss';

function Page(props) {
    document.title = '绑定结果';
    const { type } =  props.location.state;
    // const { dispatch } = useContext(context);
    const [codeCount, setCodeCount] = useState(3);
    
    useEffect(() => {
        let timer = null;
        if (codeCount <= 3 && codeCount > -1 && type === 1 ) {
            if (codeCount === 0) {
                if(type === 1){
                    props.history.push('/bank');
                }
            } else {
                timer = setTimeout(() => {
                    setCodeCount(codeCount - 1);
                }, 1000)
            }
        }
        return () => {
            timer && clearTimeout(timer)
        }
    }, [codeCount]);

    // if (type === 1) {
    //     dispatch({ type: 'resetAddBank' ,payload: { user: '',
    //     bankType: '',
    //     bankNo: '',
    //     phone: '',
    //     slectTreaty: true} });
    // }

    return (
        <div className="bind-result">
            {
                type === 1
                    ? <div>
                        <div className="succ"><i className="iconfont">&#xe602;</i></div>
                        <div className="">绑定成功</div>
                        <div className="count-down">跳转倒计时&#8194;<span>{codeCount}s</span></div>
                    </div>
                    : type === 2
                        ? <div>
                            <div className="fail"><i className="iconfont">&#xe605;</i></div>
                            <div className="">绑定失败</div>
                            <div className="count-down count-down2">您的<span>银行信息</span>或<span>手机号</span>有误</div>
                            <button className="form-btn tran3" onClick={(e) => {
                                e.preventDefault();
                                props.history.push('/addBank')
                            }}>重新绑定</button>
                        </div>
                        : null
            }
        </div>
    );
}

export default Page