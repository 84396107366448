import React from 'react';
import './index.scss';

function Page() {
    document.title = '协议';
    return (
        <div className='agreementWrap'>
            <h3>用户服务协议</h3>
            <p style={{textIndent: '2em'}}><b>您应当阅读并遵守本《用户服务协议》（以下简称“本协议”）。本协议是用户与平台之间的法律协议，是用户注册或登录平台账号并使用平台服务的通用条款。请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款。限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。如果您不同意本协议的约定，您应立即停止注册、登录程序或停止使用平台服务；如您继续访问和使用平台服务，即视为您已确知并完全同意本协议各项约定。</b>	</p>
            <p className='addBold addBoldColor'><b>一、定义 </b></p>
            <p>1、平台，是指中和智家（深圳）科技有限公司旗下运营网站（www.shouyuhome.com)、移动站点（包括m.shouyuhome.com），平台系专注于为用户提供房产信息搜索服务、房产租赁在线交易服务及网络推广服务的互联网平台。</p>
            <p>2、用户，以下亦称为“您”，系指注册或登录平台使用产品或服务的具有完全民事权利能力和行为能力的企业法人、社会团体、自然人或其他社会组织等。按用户是否经过注册程序分为注册用户和非注册用户，注册用户是指通过平台完成全部注册程序后，使用平台服务或网站资料的用户。非注册用户是指未进行注册、直接登录平台或通过其他平台允许的方式进入平台直接或间接地使用平台服务或网站资料的用户。</p>
            <p className='addBold addBoldColor'>二、协议的效力</p>
            <p className='addBold'>1、在您按照注册、登录页面提示填写信息、阅读并同意本协议或以其他平台允许的方式实际使用平台服务时，您即受本协议的约束。</p>
            <p className='addBold'>2、本协议内容包括本协议正文，您在使用平台某一特定服务或系统时，该服务可能会另有单独的协议、相关业务规则、公告、帮助、说明、通用条款等（以下统称为“规则”）。所有平台已经发布的或将来可能发布的规则均为本协议不可分割的组成部分，与本协议具有同等法律效力。除另行明确声明外，任何平台及其关联公司提供的服务（以下称为“平台服务”）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册、登录程序或停止使用平台服务；如您继续访问和使用平台服务，即视为您已确知并完全同意本协议各项内容。</p>
            <p className='addBold addBoldColor'>三、注册、登录</p>
            <p>1、您确认，在您完成注册程序或以其他平台允许的方式实际使用平台服务时，您应当是具备相应民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人（以下统称"监护人"）应承担因此而导致的一切后果，且平台有权注销您的账户，并向您及您的监护人索偿。</p>
            <p>2、平台非常重视对青少年个人信息搜集和使用的安全性的保护。任何未满18周岁的未成年人参加网上活动应事先取得其监护人可经查证的同意并遵守《全国青少年网络文明公约》。监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。</p>
            <p className='addBold'>3、在您签署本协议，完成注册或登录程序时，平台会向您提供唯一编号的平台账户。您应对您的用户账户、登录密码、支付密码（如有）、验证码的安全以及对通过您的账户实施的行为负责，除非有法律规定或行政司法机关的指令，且征得平台的同意，否则您的用户账户不得以任何方式转让、借用、赠与或继承（与账户相关的财产权益除外），您的登录密码、支付密码（如有）和验证码不得对任何第三方提供或泄露，否则，由此给您（或平台、任何第三方）造成的一切损失，概由您自行承担（或者负责赔偿），平台不承担任何责任。</p>
            <p>4、如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即与平台客服联系并通知平台暂停或冻结相关服务。您理解平台对您的请求采取行动需要合理时间，平台对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
            <p className='addBold addBoldColor'>四、交易</p>
            <p className='addBold'>如您使用平台提供的在线交易服务，您认可并自愿使用电子合同服务方式签署合同（或协议），接受并认可通过平台签署的电子合同（或协议）与纸质合同（或协议）具备同等法律效力，接受并认可您电子签名与您手写签名具备同等法律效力。您同意并不可撤销地对乙方进行以下授权：</p>
            <p className='addBold'>1、您向乙方申请开通第三方服务的（包括但不限于电子合同服务、个人履约信息服务、第三方支付服务等），即视为您同意并授权我们将您的信息提供给与平台合作的提供该服务的第三方如电子签约公司，您信息包括但不限于您向平台提供的信息（如甲方向乙方提供的个人信息、征信信息以及甲方CA数字证书信息等）以及您在平台录入的信息。</p>
            <p className='addBold'>2、授权平台及其合作的第三方通过合法渠道查询或核实您的信息（法律、法规、监管政策禁止查询的除外）并有权基于您使用平台的需要而保存、整理或加工查获的信息。平台及其合作的第三方可依据前述信息持续性地向您提供相应服务（包括但不限于将信息用于向您推广、提供其他更加优质的产品或服务）。因您提供信息虚假或无效导致的一切法律责任由您承担。</p>
            <p className='addBold'>3、授权平台及提供电子合同服务的第三方根据您向平台提供的资料开通电子合同服务（您需首先通过平台及其合作的第三方对其进行的合法、有效的实名认证，且取得相应的CA数字证书），并生成电子印章。授权平台及提供电子合同服务的第三方在您使用电子合同服务期间或在电子合同服务终止后，有权保留您在使用电子合同服务期间所形成的相关信息数据。</p>
            <p className='addBold'>4、授权平台以您名义开通他第三方支付账户，支付您及其签约方签署的合同项下的相关款项，并向平台支付其他服务费，平台向您提供的收费服务，将在平台予以公示并由您自主选择。</p>
            <p className='addBold'>5、您在平台的账户、密码是我们识别您身份及指令的唯一标志，所有使用您账户、密码的操作（包括但不限于信息披露、信息发布、点击同意或提交各类规则协议、签署电子合同等）均为您或您的授权操作行为，任何使用您账户、密码发送至平台或其合作的第三方的指令均构成您不可撤销的授权指示，平台及其合作的第三方对于依照该指令进行的操作行为及其结果不承担任何责任。</p>
            <p>6、如您发现账号有任何异常操作，请及时联系我们的客服。</p>
            <p className='addBold addBoldColor'>五、平台服务使用规范</p>
            <p>1、通过平台，您可以按照平台的规则发布各种生活信息。但所发布之信息不得含有如下内容：</p>
            <p>1) 反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
            <p>2)煽动危害国家安全、泄露国家秘密、颠覆国家政权，推翻社会主义制度的；</p>
            <p>3)煽动分裂国家、破坏国家统一、损害国家荣誉和民族利益的；	</p>
            <p>4)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>5)捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
            <p>6)进行政治宣传或破坏国家宗教政策、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
            <p>7)公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
            <p>8)损害国家机关信誉的；</p>
            <p>9)其他违反宪法和法律法规的；</p>
            <p>2、在接受平台服务的过程中，您不得从事下列行为：</p>
            <p>1）发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容，包含病毒、木马、定时炸弹等可能对平台系统造成伤害或影响其稳定性的内容制造虚假身份以误导、欺骗他人；</p>
            <p>2）传送或散布以其他方式实现传送的含有受到知识产权法律保护的图像、相片、软件或其他资料的文件，作为举例（但不限于此）：包括版权或商标法（或隐私权或公开权），除非用户拥有或控制着相应的权利或已得到所有必要的认可；</p>
            <p>3）使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方的其他专有权利的方式利用本“软件”获得的图像或相片的资料或信息；</p>
            <p>4）进行危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本平台系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；</p>
            <p>5）修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件 的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营；</p>
            <p>6）在未经平台书面明确授权前提下，出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；</p>
            <p>7）违背平台页面公布之活动规则，包括但不限于发布虚假信息、作弊或通过其他手段进行虚假交易。</p>
            <p>3、您了解并同意，平台有权应政府部门（包括司法及行政部门）的要求，向其提供您在平台填写的注册信息和发布纪录等必要信息。</p>
            <p>4、在平台上使用平台服务过程中，您承诺遵守以下约定：</p>
            <p>1)在使用平台服务过程中实施的所有行为均遵守国家法律、法规等规范文件及平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保免于因此产生任何损失或增加费用。</p>
            <p>2)不发布国家禁止发布的信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。</p>
            <p>3)不对平台上的任何数据作商业性利用，包括但不限于在未经平台事先书面同意的情况下，以复制、传播等任何方式使用平台站上展示的资料。</p>
            <p>4)不使用任何装置、软件或例行程序干预或试图干预平台的正常运作或正在平台上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸平台网络设备的行动。</p>
            <p>5、您了解并同意：</p>
            <p>1)您违反上述承诺时，平台有权依据本协议的约定，做出相应处理或终止向您提供服务，且无须征得您的同意或提前通知于您。</p>
            <p>2)根据相关法令的指定或者平台服务规则的判断，您的行为涉嫌违反法律法规的规定或违反本协议和/或规则的条款的，平台有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息、降低您的信用值或直接停止提供服务。</p>
            <p>3)对于您在平台上实施的行为，包括您未在平台上实施但已经对平台及其用户产生影响的行为，平台有权单方认定您行为的性质及是否构成对本协议和/或规则的违反，并据此采取相应的处理措施。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据承担不利后果。</p>
            <p>4)对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保平台免于承担因此产生的损失或增加的费用。</p>
            <p>5)如您涉嫌违反有关法律或者本协议之规定，使平台遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿平台因此造成的损失及（或）发生的费用，包括合理的律师费用。</p>
            <p className='addBold addBoldColor'>六、责任范围和责任限制</p>
            <p className='addBold'>1、平台负责向您提供信息服务及在线交易服务。但平台对服务不作任何明示或暗示的保证，包括但不限于平台信息服务的准确性、适用性、没有错误、疏漏或适用于某一特定用途。平台应采取相应措施保护平台的安全性、稳定性、持续性、可靠性。</p>
            <p className='addBold'>2、平台仅向您提供信息服务，您了解平台上的信息系用户自行发布，由于海量信息的存在，且平台无法杜绝可能存在风险和瑕疵。您应谨慎判断确定相关信息的真实性、合法性和有效性，并注意保留相应的证据以利于维权。</p>
            <p>3、平台与其他的在线使用的互联网网站一样，也会受到各种不良信息、网络安全和网络故障问题的困扰，包括但不限于：</p>
            <p>1）其他用户可能会发布诈骗或虚假信息，或者发表有谩骂、诅咒、诋毁、攻击内容的等让人反感、厌恶的内容的非法言论；</p>
            <p>2）其他用户可能会发布一些侵犯您或者其他第三方知识产权、肖像权、姓名权、名誉权、隐私权和/或其他合法权益的图片、照片、文字等资料；</p>
            <p>3）面临着诸如黑客攻击、计算机病毒困扰、系统崩溃、网络掉线、网速缓慢、程序漏洞等问题的困扰和威胁。</p>
            <p className='addBold'>您充分了解并同意：上述的各种不良信息、网络安全和网络故障问题，并不是平台所导致的问题，由此可能会造成您感到反感、恶心、呕吐等精神损害，或者给您造成其他的损失，概由您自行承担，平台无须向您承担任何责任。</p>
            <p>4、您同意，在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，您有义务及时通知平台。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知平台并同时提供如下信息和材料：</p>
            <p>1)侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；</p>
            <p>2)您是所述的版权或者其他权利的合法拥有者的权利证明；</p>
            <p>3)您的个人信息及有效联系方式，包括真实姓名，身份证号、地址，电话号码和电子邮件等；</p>
            <p>4)您的身份证复印件、营业执照等其他相关资料。</p>
            <p>经审查得到证实的，我们将及时删除相关信息。我们仅接受邮寄、电子邮件或传真方式的书面侵权通知。情况紧急的，您可以通过客服电话先行告知，我们会视情况采取相应措施。</p>
            <p className='addBold'>5、您了解并同意，平台不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿：</p>
            <p>1) 使用或未能使用平台服务；</p>
            <p>2) 第三方未经批准地使用您的账户或更改您的数据；</p>
            <p>3) 通过平台购买、获取任何服务、数据、信息等行为或替代行为或与其他任何第三方搭建线上/线下交易、服务法律关系之间的全部事宜产生的费用及损失；</p>
            <p>4) 您对平台服务的误解；</p>
            <p>5) 因不可抗力或其他因非平台的原因而引起的与平台服务有关的其它损失。</p>
            <p className='addBold'>6、您在平台上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议及其他相关规则。平台和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</p>
            <p className='addBold'>7、您同意在使用平台服务过程中显示平台自行或由第三方服务商向您发送或提供的推广或宣传信息、资讯、动态等（统称为推广信息，包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。除法律法规明确规定外，您应自行对依该推广信息进行的交易审慎评估理性接受，并对交易后果负责，对用户因依该推广信息进行的交易或前述第三方服务商提供的内容因而遭受的损失或损害，平台不承担任何责任。</p>
            <p>8、平台对下列不可抗力行为免责：信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务。</p>
            <p>9、您应当严格遵守本协议及平台规则等，因您违反协议或规则的行为给第三方或平台造成损失的，您应当承担全部责任。</p>
            <p>10、平台保留在中华人民共和国大陆地区施行之法律允许的范围内独立决定拒绝服务、关闭用户账户、审查、编辑、清除用户发布内容或取消订单的权利。</p>
            <p className='addBold addBoldColor'>七、协议终止</p>
            <p>1、您同意，平台基于服务的安全性，有权中止向您提供部分或全部平台服务，暂时冻结您的账户，待安全问题解决后及时恢复，并对中止、冻结及恢复的事实及时通知。如果网站的安全问题是由于您的违法行为引起，平台有权终止向您提供部分或全部平台服务，永久冻结（注销）您的帐户，并有权向您对损失进行索赔。</p>
            <p>2、您同意，您与平台的协议关系终止后，平台仍享有下列权利：</p>
            <p>1) 按法律规定保存您在使用平台服务期间的信息发布记录、交易记录及及其他登陆使用操作记录等。</p>
            <p>2）您在使用平台服务期间存在违法行为或违反本协议和/或规则的行为的，平台仍可依据本协议向您主张权利、追究责任。</p>
            <p className='addBold addBoldColor'>八、隐私权政策</p>
            <p>1、平台将在公布并不时修订隐私权条款，隐私权条款构成本协议的有效组成部分。</p>
            <p>2、您知悉并认可：平台可能会与第三方合作商向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本网站同等的保护用户隐私的责任，则您同意平台有权将您的个人注册信息及注册资料等提供给该第三方。另外，在对您信息进行脱敏处理后，平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
            <p>3、平台将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。对此您表示理解和认同。</p>
            <p>4、平台不会将您的个人信息转移或披露给任何非关联的第三方，除非：</p>
            <p>1）相关法律法规或法院、政府机关要求；</p>
            <p>2）为完成合并、分立、收购或资产转让而转移；</p>
            <p>3）为提供您要求的服务所必需；</p>
            <p>4）为您提供任何您可能感兴趣的信息或服务。</p>
            <p>5、您知悉并认可：平台通过推广或其他方式向您提供链接，使您可以接入第三方服务或网站。您使用该等第三方的服务时，须受该第三方的服务条款及隐私政策约束，您需要仔细阅读其条款。本协议仅适用于平台提供的服务器所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，平台对任何第三方使用由您提供的信息不承担任何责任。</p>
            <p>6、您知悉并认可：为保护您的个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄漏。对此您表示理解和认同。</p>
            <p>7、平台不对用户所发布信息的删除或储存失败负责。平台并不承诺对用户的存储信息进行无限期保留。</p>
            <p>8、当您终止使用平台的服务注销平台账号的，平台将停止收集并使用您的个人信息。</p>
            <p className='addBold addBoldColor'>九、知识产权声明</p>
            <p>1、凡侵犯本公司版权等知识产权的，平台将依法追究其相关法律责任。</p>
            <p>2、平台所刊登的资料信息（包括但不限于编码、文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是平台或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是平台的排他财产，受中国和国际版权法的保护。本平台上所有软件都是平台或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。任何被授权的浏览、复制、打印和传播属于平台网站内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明。</p>
            <p>3、用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于用户问答、评价、用户咨询、各类资讯、知识、百科、意见、话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给平台所有，用户同意平台有权就任何主体侵权而单独提起诉讼。</p>
            <p>4、本协议已经构成《中华人民共和国著作权法》及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在平台上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</p>
            <p>5、用户同意并已充分了解本协议的条款，承诺不将已发表于平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</p>
            <p>6、用户通过平台发布的信息或内容，并不代表平台之意见及观点，也不意味着平台赞同其观点或证实其内容的真实性。</p>
            <p className='addBold'>7、用户通过平台发布的信息、文字、图片等资料均由平台用户提供，其真实性、准确性和合法性由信息发布人负责。平台不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，平台对此不承担责任。</p>
            <p className='addBold'>8、除法律另有强制性规定外，未经平台明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、传播、展示、镜像、上载、下载、转载、引用、链接、抓取或以其他方式使用平台平台的信息内容及相关资料，否则，平台有权追究其法律责任。</p>
            <p>9、平台以下内容不可任意转载：</p>
            <p>1）本平台内发布的所有信息；</p>
            <p>2）已作出不得转载或未经许可不得转载声明的内容；</p>
            <p>3）本平台中特有的图形、标志、页面风格、编排方式、程序等；</p>
            <p>4）本平台中必须具有特别授权或具有注册用户资格方可知晓的内容；</p>
            <p>5）其他法律不允许或本平台认为不适合转载的内容。</p>
            <p>10、对于不当引用本平网站内容而引起的纷争等或因纠纷等造成的任何损失，本平不承担相关法律责任。对不遵守本声明的用户或其他违法、恶意使用平台内容者，本平保留追究其法律责任的权利。</p>
            <p className='addBold addBoldColor'>十、法律适用、管辖与其他</p>
            <p>1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律，如法律无相关规定的，则应参照通用国际商业惯例和（或）行业惯例。</p>
            <p>2、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
            <p className='addBold'>3、因本协议产生之争议、纠纷，应由平台与您友好协商解决；协商不成的，任何一方均有权向北京市海淀区人民法院诉讼解决。</p>
            <p className='addBold'>为给您提供更好的服务以及随着国家法律法规的调整、平台业务的发展，本协议也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在平台网站、平台移动端上发出更新版本并在生效前通过适当方式提醒您相关内容的更新，也请您访问平台以便及时了解最新的隐私政策</p>
            <p className='addBold'>为维护网民和相关权利人利益，中和智家（深圳）科技有限公司已经以公示的方式，为用户及相关权利人提供了丰富、便捷的线上线下投诉渠道。基于网络用户间的公平考量，同时也为了使网络用户的投诉能够及时得到处理，避免被当做垃圾邮件屏蔽，中和智家（深圳）科技有限公司拒绝接收和处理所有以电子邮件形式发送给本公司员工个人的网络侵权通知，更不承诺对发送给本公司员工个人的电子邮件侵权通知进行优先处理，特此告知</p>
            <p>本协议自2019年7月1日生效。</p>
            <p className='addBold'>本协议内容中以加粗方式显著标识的条款，请您着重阅读。您点击“立即注册”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</p>
        </div>
    )
}

export default Page