
import React from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "./store";
import Login from './page/Login';
import Bill from './page/Bill';
import My from './page/My';
import Bank from './page/Bank';
import AddBank from './page/AddBank';
import VCode from './page/vCode';
import AddBankSelect from './page/AddBankSelect';
import About from './page/About';
import BindResult from './page/BindResult';
import MoreBill from './page/MoreBill';
import Identity from './page/Identity';
import FaceAuth from "./page/FaceAuth";
import FAQ from './page/FAQ';
import Pay from './page/Pay';
import PayTips from './page/PayTips';
import AuthenResult from './page/AuthenResult';
import PayResult from './page/PayResult';
import PayResult2 from './page/PayResult2';
import PayResult3 from './page/PayResult3';
import PayResult4 from './page/PayResult4';
import BestsignResult from './page/BestsignResult';
import Agreement from './page/Agreement';
import NumberCertificate from './page/NumberCertificate';
import Page404 from './page/Page404';
import AttachPage from "./page/AttachPage";
import StewardLogin from './page/StewardLogin';


function App() {
    window.loadRem();
    return (
        <Provider>
            <Router>
                <Switch>
                    <Redirect exact from="/" to="/login" />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/steward-login" component={StewardLogin} />
                    <Route
                        render={({ location }) => {
                            // if(!Cookies.get('loginData')){
                            //     return <Redirect exact from="/" to="/login" />
                            // }
                            return <TransitionGroup>
                                <Switch>
                                    {/* <Route exact path="/login" component={Login} /> */}
                                    <Route exact path="/bill" component={Bill} />
                                    <Route exact path="/my" component={My} />
                                    <CSSTransition
                                        key={location.key}
                                        classNames="fade"
                                        timeout={300}
                                    >
                                        <Switch>
                                            <Route exact path="/bank" component={Bank} />
                                            <Route exact path="/addBank" component={AddBank} />
                                            <Route exact path="/vCode" component={VCode} />
                                            <Route exact path="/addBankSelect" component={AddBankSelect} />
                                            <Route exact path="/bindResult" component={BindResult} />
                                            <Route exact path="/about" component={About} />
                                            <Route exact path="/bill/more/:houseId/:contractId" component={MoreBill} />
                                            <Route exact path="/identity" component={Identity} />
                                            <Route exact path="/faceAuth" component={FaceAuth} />
                                            
                                            <Route exact path="/faq" component={FAQ} />
                                            <Route exact path="/pay/ys" component={Pay} />
                                            <Route exact path="/pay/tips" component={PayTips} />
                                            <Route exact path="/authenResult" component={AuthenResult} />
                                            <Route exact path="/payResult" component={PayResult} />
                                            <Route exact path="/payResult2" component={PayResult2} />
                                            <Route exact path="/ccbPayResult" component={PayResult3} />
                                            <Route exact path="/hsbPayResult" component={PayResult4} />
                                            <Route exact path="/bestsignResult" component={BestsignResult} />
                                            <Route exact path="/agreement" component={Agreement} />
                                            <Route exact path="/numberCertificate" component={NumberCertificate} />
                                            <Route exact path="/attachPage/:contractId" component={AttachPage} />

                                            
                                            <Route component={Page404} />
                                        </Switch>
                                    </CSSTransition>
                                </Switch>
                            </TransitionGroup>
                        }} />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App