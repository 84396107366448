import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import qs from 'qs';
import './index.scss';
import { post, config } from '../../axios';
import Toast from '../../component/Toast';

function Page(props) {
    const [result, setResult] = useState(null); //认证结果
    const [showToast, setShowToast] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    let { search } = props.location;
    search = qs.parse(search, { ignoreQueryPrefix: true });
    let loginData = {};
    if (search.loginInfo) {
        Cookies.set('loginData', search.loginInfo);
        loginData = JSON.parse(Cookies.get('loginData'));
    }

    useEffect(() => {
        if (search) {
            if (search.from === 'faceResult') {
                // 人脸识别
                if (search.result === 'true') {
                    // 认证成功
                    if (loginData.signableContractId > 0) {
                        // 需要签约
                        document.title = '处理中';
                        setResult('success');
                        getSignUrl();
                    } else {
                        // 不需要签约
                        props.history.push('/bill');
                    }
                } else {
                    // 认证失败
                    document.title = '认证失败';
                    setResult('fail');
                }
            } else if (search.from === 'login') {
                // 不需要人脸识别
                document.title = '处理中';
                setResult('success');
                getSignUrl();
            }
        }

        // 请求签约地址
        function getSignUrl() {
            post({ url: config.API_bestsign_getSignUrl }).then(res => {
                if (res.code === 0) {
                    let data = JSON.parse(res.data);
                    window.location.href = data.longUrl;
                } else {
                    // toastMsg(res.message)
                    setTimeout(() => {
                        getSignUrl();
                    }, 1000)
                }
            }).catch(err => {
                console.log(err)
                toastMsg(err.message)
            })
        }
    }, []);

    function toastMsg(msg) {
        setShowToast(true);
        setErrorMsg(msg);
        setTimeout(() => {
            setShowToast(false);
        }, 12000)
    }

    return (
        <div className="face-recognition">
            {
                result === 'success'
                    ? <div className="processing">
                        <i className="iconfont icon">&#xe6b5;</i>
                        <div className="exp">正在处理，请稍等</div>
                    </div>
                    : result === 'fail'
                        ? <div className="fail">
                            <i className="iconfont icon">&#xe60e;</i>
                            <div className="exp">
                                <p>认证失败</p>
                                <button className="form-btn tran3" onClick={() => {
                                    window.location.href = loginData.faceIdentifyUrl;
                                }}>重新认证</button>
                                <p className="exp2">注：当日认证失败超过3次，请第2日再试。<span className="num">(1/3)</span></p>
                            </div>
                        </div>
                        : null
            }
            <Toast
                show={showToast}
                content={<div>{errorMsg}</div>}
            />
        </div>
    );
}

export default Page